import { combineEpics } from "redux-observable";
import TicketEpic from './TicketApi/Ticket.epic'
import TaskEpic from "./TaskApis/Task.epic";

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author
 */

const rootEpic = combineEpics(
    TicketEpic.loginApi,
    TicketEpic.ticketListApi,
    TicketEpic.loginInfo,
    TicketEpic.getCache,
    TicketEpic.typeList,
    TicketEpic.userList,
    TicketEpic.rowUpdate,
    TicketEpic.bulkUpdate,
    TicketEpic.searchPatientApi,
    TicketEpic.newTicketApi,
    TicketEpic.ticketNotesApi,
    TicketEpic.newTicketNotes,
    TaskEpic.taskListApi,
    TaskEpic.newTaskApi,
    TaskEpic.taskRowUpdate,
    TaskEpic.taskBulkUpdate,
    TicketEpic.fileUpload,
    TicketEpic.fileDownload
)
export default rootEpic;