/**
 * @fileOverview Manages the response of action
 * @author 
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

import {
  LOGIN_RES,
  TICKET_LIST_RES,
  LOGIN_INFO_RES,
  CACHE_LIST_RES,
  TYPE_LIST_RES,
  USER_LIST_RES,
  ROW_UPDATE_RES,
  BULK_UPDATE_RES,
  SEARCH_PATIENT_RES,
  TICKET_FILTER,
  NEW_TICKET_RES,
  TICKET_NOTES_RES,
  NEW_TICKET_NOTES_RES,
  FILE_UPLOAD_RES,
  FILE_DOWNLOAD_RES,
} from "./Ticket.types";

const INITIAL_STATE = {
  loginResponse: null,
  ticketTable: null,
  cache: null,
  cacheList: null,
  typeList: null,
  userList: null,
  rowUpdate: null,
  bulkUpdate: null,
  searchPatient: null,
  ticketFilter: {
    clinic: null,
    type: null,
    from: null,
    owner: 0,
    dueDate: null,
    status: 0,
    priority: null,
  },
  newTicket: null,
  ticketNotes: null,
  newTicketNotes: null,
  fileUpload: null,
  fileDownload: null,
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RES: {
      return { ...state, ...{ loginResponse: action.payload } };
    }
    case TICKET_LIST_RES: {
      return { ...state, ...{ ticketTable: action.payload } };
    }
    case LOGIN_INFO_RES: {
      return { ...state, ...{ cache: action.payload } };
    }
    case CACHE_LIST_RES: {
      return { ...state, ...{ cacheList: action.payload } };
    }
    case TYPE_LIST_RES: {
      return { ...state, ...{ typeList: action.payload } };
    }
    case USER_LIST_RES: {
      return { ...state, ...{ userList: action.payload } };
    }
    case ROW_UPDATE_RES: {
      return { ...state, ...{ rowUpdate: action.payload } };
    }
    case BULK_UPDATE_RES: {
      return { ...state, ...{ bulkUpdate: action.payload } };
    }
    case SEARCH_PATIENT_RES: {
      return { ...state, ...{ searchPatient: action.payload } };
    }
    case TICKET_FILTER: {
      return { ...state, ...{ ticketFilter: action.payload } };
    }
    case NEW_TICKET_RES: {
      return { ...state, ...{ newTicket: action.payload } };
    }
    case TICKET_NOTES_RES: {
      return { ...state, ...{ ticketNotes: action.payload } };
    }
    case NEW_TICKET_NOTES_RES: {
      return { ...state, ...{ newTicketNotes: action.payload } };
    }
    case FILE_UPLOAD_RES: {
      return { ...state, ...{ fileUpload: action.payload } };
    }
    case FILE_DOWNLOAD_RES: {
      return { ...state, ...{ fileDownload: action.payload } };
    }
    default:
      return state;
  }
};
export default Reducer;
