import { combineReducers } from "redux";
import TicketReducer from './TicketApi/Ticket.reducer'
import TaskReducer from './TaskApis/Task.reducer'

/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author 
 */

const rootReducer = combineReducers({
    Ticket: TicketReducer,
    Task: TaskReducer
})

export default rootReducer