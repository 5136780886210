import React from "react";
import "./style.css";
import { FormControl, TextField } from "@material-ui/core";

/**
 * @fileOverview Reusable Input field
 * @component
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @param {props} props will contain all the latest values for the autocomplete like value, onChange,Label
 * @return {JSXElement}
 * @example
 * return(
 * <Input/>
 * )
 */
function Input(props) {
  const handleInput = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };
  const defaultValidation = (value) => "";

  return (
    <FormControl style={{ width: "100%" }}>
      <TextField
        tabIndex={props.tabIndex ? props.tabIndex : null}
        onChange={handleInput}
        type={props.type ? props.type : "text"}
        disabled={props.disabled ? props.disabled : false}
        label={props.placeholder ? props.placeholder : ""}
        fullWidth={props.fullWidth ? props.fullWidth : false}
        inputProps={{
          readOnly: props.readOnly ? true : false,
        }}
        {...props}
        // error={props.validation(props.value)} // Use the validation function
        // helperText={props.validation(props.value) || ""}
        error={props.validation ? props.validation(props.value) : defaultValidation(props.value)}
        helperText={props.validation ? props.validation(props.value) : ""}
      />
    </FormControl>
  );
}
export default Input;
