import { React, useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import Dropdown from "../../Components/Dropdown";
import DobPicker from "../../Components/dobPicker";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  taskList,
  taskFilter,
  taskRowUpdate,
  taskBulkUpdate,
} from "../../Redux/TaskApis/Task.action";
import EditIcon from "@material-ui/icons/Edit";
import CommentIcon from "@material-ui/icons/Comment";
import {
  getCache,
  ticketList,
  ticketNotes,
  typeList,
} from "../../Redux/TicketApi/Ticket.action";
import {
  TimestamptoTime,
  dateTommddyyy,
  dateToyyymmdd,
} from "../../HelperFunctions/TimeDateTimestamp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import NewTaskDialog from "../../Components/NewTaskDialog";
import toast from "react-hot-toast";
import noDataFound from "../../assets/nodata-found.png";
import Date from "../../Components/Date/Date";
import Comments from "../../Components/Comments";
import "./style.css";

function Tasks(props) {
  const owner = JSON.parse(localStorage.getItem("owner"));
  const [headCells, setHeadCells] = useState([
    {
      active: false,
      direction: "desc",
      label: "Ticket No",
      item: "ticket",
    },
    { active: false, direction: "desc", label: "Subject", item: "subject" },
    {
      active: false,
      direction: "desc",
      label: "Task Description",
      item: "description",
    },
    { active: false, direction: "desc", label: "Clinic Name", item: "clinic" },
    { active: false, direction: "desc", label: "From", item: "assignedByUser" },
    {
      active: false,
      direction: "desc",
      label: "Owner",
      item: "assignedToUser",
    },
    {
      active: false,
      direction: "desc",
      label: "Type",
      item: "workflowTypeMaster",
    },
    { active: false, direction: "desc", label: "Opened", item: "assignedOn" },
    { active: false, direction: "desc", label: "Due Date", item: "dueDate" },
    { active: false, direction: "desc", label: "Status", item: "status" },
  ]);

  const Status = [
    // { name: "Open", value: 5 },
    { name: "Not started", value: 1 },
    { name: "In progress", value: 2 },
    { name: "Completed", value: 3 },
    { name: "Cancelled", value: 4 },
    { name: "Resolved", value: 6 },
  ];

  const Priority = [
    { name: "High", value: 1 },
    { name: "Medium", value: 2 },
    { name: "Low", value: 3 },
  ];

  const [rows, setRows] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [forms, setForms] = useState({
    clinic: null,
    type: null,
    from: null,
    owner: owner.userId,
    dueDate: null,
    status: 2,
    priority: null,
  });
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState([]);
  const [openNotes, setOpenNotes] = useState(false);
  const [taskInfo, setTaskInfo] = useState([]);
  const [checkBoxDto, setCheckBoxDto] = useState([]);
  const [actionForm, setActionForm] = useState({
    owner: null,
    type: null,
    status: null,
  });
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [workFlowType, setWorkFlowType] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("owner"));
    let filter = props.Tasks.taskFilter;
    props.taskList({
      start: page,
      limit: rowsPerPage,
      sortBy: sortBy,
      sortDirection: sortDirection,
      ticketTaskDto: {
        ticketTaskId: null,
        ticketId: null,
        workflowStepId: null,
        assignedBy: filter.from ? filter.from : null,
        assignedToRole: null,
        // assignedTo: filter.owner ? filter.owner : null,
        assignedTo:
          filter.owner === 0
            ? user.userId
            : filter.owner !== 0
            ? filter.owner
            : null,
        assignedOn: null,
        dueDate: filter.dueDate
          ? dateToyyymmdd(filter.dueDate) + "T12:00:00.000Z"
          : null,
        // status: filter.status ? filter.status : null,
        status:
          filter.status === 0 ? 2 : filter.status !== 0 ? filter.status : null,
        reminderOn: null,
        clinicId: filter.clinic ? filter.clinic : null,
        workflowType: filter.type ? filter.type : null,
        priority: filter.priority ? filter.priority : null,
        loggedUser: user.userId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    sortBy,
    sortDirection,
    props.Tasks.taskFilter,
    props.Tasks.taskRowUpdate,
  ]);

  useEffect(() => {
    if (props.Tasks.taskTable) {
      let data = props.Tasks.taskTable.response.data;
      setRows(data.result.ticketTaskDtoList);
      setCount(data.totalRecords);
    }
  }, [props.Tasks.taskTable]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("cache"));
    let clinicList = [];
    let usersList = [];
    cache.clinicDtoList.forEach((x) => {
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });
    cache.userDtoList.forEach((x) => {
      usersList.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    // Sort the owner array alphabetically by name
    usersList.sort((a, b) => a.name.localeCompare(b.name));
    setClinicList(clinicList);
    setUser(usersList);
  }, []);

  const handleSort = (index) => {
    let tableHead = headCells;
    tableHead.forEach((x, i) => {
      if (i === index) {
        tableHead[i].active = true;
        tableHead[i].direction =
          tableHead[i].direction === "asc" ? "desc" : "asc";
        setSortBy(tableHead[i].item);
        setSortDirection(tableHead[i].direction === "asc" ? "desc" : "asc");
      } else {
        tableHead[i].active = false;
        tableHead[i].direction = "desc";
      }
    });
    setHeadCells([...tableHead]);
  };

  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  const handleNewTask = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleNotes = (x) => {
    localStorage.setItem("taskId", x.ticketTaskId);
    setOpenNotes(true);
    props.ticketNotes({
      start: 0,
      limit: 10,
      sortBy: null,
      sortDirection: null,
      ticketCommentDto: {
        ticketCommentId: null,
        comment: null,
        ticketId: null,
        taskId: x.ticketTaskId,
      },
    });
    setTaskInfo({
      name: x.assignedByName,
      number: x.ticketNumber,
      date: x.assignedOn
        ? dateTommddyyy(x.assignedOn) + " " + TimestamptoTime(x.assignedOn)
        : "",
      taskId: x.ticketTaskId,
      subject: x.subject,
      description: x.description,
    });
  };

  const notesClose = () => {
    setOpenNotes(false);
  };

  const ownerUpdate = (e, x, i) => {
    let row = rows;
    row[i].assignedTo = e.target.value;
    setRows([...row]);
    props.rowUpdate({
      ticketTaskId: null,
      ticketId: null,
      workflowStepId: null,
      assignedBy: null,
      assignedToRole: null,
      assignedTo: e.target.value,
      dueDate: null,
      status: null,
      reminderOn: null,
      clinicId: null,
      priority: null,
      description: null,
      subject: null,
      id: x.ticketTaskId,
    });
  };

  const typeUpdate = (e, x, i) => {
    let row = rows;
    row[i].workflowType = e.target.value;
    setRows([...row]);
    props.rowUpdate({
      ticketTaskId: null,
      ticketId: null,
      workflowStepId: null,
      workflowType: e.target.value,
      assignedBy: null,
      assignedToRole: null,
      assignedTo: null,
      dueDate: null,
      status: null,
      reminderOn: null,
      clinicId: null,
      priority: null,
      description: null,
      subject: null,
      id: x.ticketTaskId,
    });
  };

  const statusUpdate = (e, x, i) => {
    let row = rows;
    row[i].status = e.target.value;
    setRows([...row]);
    props.rowUpdate({
      ticketTaskId: null,
      ticketId: null,
      workflowStepId: null,
      assignedBy: null,
      assignedToRole: null,
      assignedTo: null,
      dueDate: null,
      status: e.target.value,
      reminderOn: null,
      clinicId: null,
      priority: null,
      description: null,
      subject: null,
      id: x.ticketTaskId,
    });
  };

  const updateDueDate = (e, x, i) => {
    if (dateTommddyyy(e.target.value)) {
      props.rowUpdate({
        ticketTaskId: null,
        ticketId: null,
        workflowStepId: null,
        assignedBy: null,
        assignedToRole: null,
        assignedTo: null,
        dueDate: dateToyyymmdd(e.target.value) + "T12:00:00.000Z",
        status: null,
        reminderOn: null,
        clinicId: null,
        priority: null,
        description: null,
        subject: null,
        id: x.ticketTaskId,
      });
    }
  };

  useEffect(() => {
    if (props.Tasks.taskRowUpdate) {
      let response = props.Tasks.taskRowUpdate;
      if (response.status === 200) {
        toast.success("Updation success");
        props.Tasks.taskRowUpdate = null;
        setEditTask(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tasks.taskRowUpdate]);

  const handleCheckBox = (e, i) => {
    let row = rows;
    row[i] = { ...row[i], isSelected: e.target.checked };
    setRows([...row]);
    let a = [];
    row.forEach((x) => {
      if (x.isSelected === true) {
        a.push({
          ticketTaskId: x.ticketTaskId,
          owner: x.assignedTo,
          type: x.workflowType,
          status: x.status,
        });
      }
    });
    setCheckBoxDto(a);
  };

  const handleBulkUpdate = () => {
    let row = rows;
    row.forEach((x) => {
      if (x.isSelected === true) {
        x.assignedTo = actionForm.owner ? actionForm.owner : x.assignedTo;
        x.workflowType = actionForm.type ? actionForm.type : x.workflowType;
        x.status = actionForm.status ? actionForm.status : x.status;
        setRows([...row]);
      }
    });
    let a = [];
    checkBoxDto.forEach((x) => {
      a.push({
        ticketTaskId: x.ticketTaskId,
        assignedTo: actionForm.owner,
        workflowType: actionForm.type,
        status: actionForm.status,
      });
    });
    if (actionForm.owner || actionForm.type || actionForm.status) {
      props.taskBulkUpdate(a);
    }
  };

  useEffect(() => {
    if (props.Tasks.taskBulkUpdate) {
      let response = props.Tasks.taskBulkUpdate.response;
      if (response.status === 200) {
        toast.success("Updation success");
        props.Tasks.taskBulkUpdate = null;
        setActionForm({
          owner: null,
          type: null,
          status: null,
        });
        setCheckBoxDto("");
        let row = rows;
        row.forEach((x) => {
          if (x.isSelected === true) {
            x.isSelected = false;
            setRows([...row]);
          }
        });
        setMainCheckbox(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tasks.taskBulkUpdate]);

  const handleCheckboxArray = (e) => {
    setMainCheckbox(e.target.checked);
    let a = [];
    rows.forEach((x) => {
      a.push({ ...x, isSelected: e.target.checked });
      setRows([...a]);
    });
    setCheckBoxDto(a);
  };

  const handleEdit = (x) => {
    setEditTask(true);
    setTaskInfo({
      taskId: x.ticketTaskId,
      subject: x.subject,
      clinicId: x.clinicId,
      clinicName: x.clinicName,
      from: x.assignedBy,
      fromName: x.assignedByName,
      owner: x.assignedTo,
      ownerName: x.assignedToUserName,
      type: x.workflowType,
      dueDate: x.dueDate,
      status: x.status,
      priority: x.priority,
      description: x.description,
      ticketId: x.ticketId,
      isTicketSubject: x.ticketId ? true : false,
    });
    if (x.ticketId) {
      props.ticketList({
        start: 0,
        limit: 200,
        sortBy: null,
        sortDirection: null,
        ticketDto: {
          ticketId: x.ticketId,
          ticketNumber: null,
          subject: null,
          priority: null,
          currentStatus: null,
          currentAssignedTo: null,
          currentWorkflowStepId: null,
          dueDate: null,
          clinicId: null,
          createdBy: null,
          workflowType: null,
          loggedUser: user.userId,
        },
      });
    }
  };

  const handleEditClose = () => {
    setEditTask(false);
  };

  useEffect(() => {
    props.typeList({
      start: 0,
      limit: 10,
      sortBy: null,
      sortDirection: null,
      workflowDto: {
        workflowMasterId: null,
        workFlow: null,
        type: null,
        auditFieldInfo: {
          createdBy: null,
          updatedBy: null,
          createdOn: null,
          updatedOn: null,
          isActive: true,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.Tickets.typeList) {
      let response = props.Tickets.typeList.response;
      if (response.status === 200) {
        let data = response.data;
        let typeList = [];
        data.forEach((x) => {
          const name =
            x.workFlow.length > 2
              ? x.workFlow.charAt(0).toUpperCase() +
                x.workFlow.slice(1).toLowerCase()
              : x.workFlow;

          typeList.push({
            name,
            value: x.workflowMasterId,
          });
        });
        setWorkFlowType(typeList);
      }
    }
  }, [props.Tickets.typeList]);

  return (
    <div className="task_main_container">
      <div className="add_new_task">
        <AddCircleIcon
          onClick={handleNewTask}
          style={{
            fontSize: "4rem",
            backgroundColor: "white",
            borderRadius: "50%",
          }}
          color="primary"
        />
        <NewTaskDialog open={openDialog} handleClose={handleClose} />
      </div>
      <Comments
        open={openNotes}
        handleClose={notesClose}
        ticketInfo={taskInfo}
      />
      <NewTaskDialog
        open={editTask}
        handleClose={handleEditClose}
        taskInfo={taskInfo}
      />
      <div className="task_filter_container">
        <div className="task_pagination_container">
          <TablePagination
            className="task_pagination"
            component="div"
            rowsPerPageOptions={[20, 25, 50, 100]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div className="task_filters">
          <div className="ticket_filters_dropdown">
            <Dropdown
              variant="standard"
              label="Clinic"
              options={[{ name: "All Clinics", value: null }, ...clinicList]}
              onChange={(e) => {
                setForms({ ...forms, clinic: e.target.value });
              }}
              value={forms.clinic}
            />
          </div>
          <div className="ticket_filters_dropdown">
            <Dropdown
              variant="standard"
              label="Type"
              options={[{ name: "All Types", value: null }, ...workFlowType]}
              onChange={(e) => {
                setForms({ ...forms, type: e.target.value });
              }}
              value={forms.type}
            />
          </div>
          <div className="ticket_filters_dropdown">
            {/* <GroupedDropdown
              user={user}
              inputLabel="From"
              onChange={(value) => {
                setForms({ ...forms, from: value });
              }}
            /> */}
            <Dropdown
              variant="standard"
              label="From"
              options={[{ name: "All Users", value: null }, ...user]}
              onChange={(e) => {
                setForms({ ...forms, from: e.target.value });
              }}
              value={forms.from}
            />
          </div>
          <div className="ticket_filters_dropdown">
            {/* <GroupedDropdown
              user={user}
              inputLabel="Owner"
              onChange={(value) => {
                setForms({ ...forms, owner: value });
              }}
            /> */}
            <Dropdown
              variant="standard"
              label="Owner"
              options={[{ name: "All Users", value: null }, ...user]}
              onChange={(e) => {
                setForms({ ...forms, owner: e.target.value });
              }}
              value={forms.owner}
            />
          </div>
          <div className="ticket_filters_date">
            <DobPicker
              label="Due Date"
              inputVariant={"standard"}
              value={forms.dueDate}
              onChange={(e) => setForms({ ...forms, dueDate: e.target.value })}
            />
          </div>
          <div className="ticket_filters_dropdown">
            <Dropdown
              variant="standard"
              label="Status"
              options={[{ name: "All", value: null }, ...Status]}
              onChange={(e) => {
                setForms({ ...forms, status: e.target.value });
              }}
              value={forms.status}
            />
          </div>
          <div className="ticket_filters_dropdown">
            <Dropdown
              variant="standard"
              label="Priority"
              options={[{ name: "All", value: null }, ...Priority]}
              onChange={(e) => {
                setForms({ ...forms, priority: e.target.value });
              }}
              value={forms.priority}
            />
          </div>

          <div className="ticket_filters_btn">
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.taskFilter(forms)}
            >
              FILTER
            </Button>
          </div>
          <div className="bulk_update">
            <div className="bulk_update_dropdown">
              {/* <GroupedDropdown
                formControlStyle={{ width: "80px" }}
                user={user}
                inputLabel="Owner"
                onChange={(value) =>
                  setActionForm({ ...actionForm, owner: value })
                }
              /> */}
              <Dropdown
                variant="standard"
                label="Owner"
                options={[{ name: "All Users", value: null }, ...user]}
                onChange={(e) =>
                  setActionForm({ ...actionForm, owner: e.target.value })
                }
                value={actionForm.owner ? actionForm.owner : ""}
              />
            </div>
            <div className="bulk_update_dropdown">
              <Dropdown
                variant="standard"
                label="Type"
                options={workFlowType}
                value={actionForm.type ? actionForm.type : ""}
                onChange={(e) =>
                  setActionForm({ ...actionForm, type: e.target.value })
                }
              />
            </div>
            <div className="bulk_update_dropdown">
              <Dropdown
                variant="standard"
                label="Status"
                options={Status}
                value={actionForm.status ? actionForm.status : ""}
                onChange={(e) =>
                  setActionForm({ ...actionForm, status: e.target.value })
                }
              />
            </div>
            <div className="bulk_update_btn">
              <Button
                variant="contained"
                color="primary"
                disabled={checkBoxDto.length > 0 ? false : true}
                onClick={handleBulkUpdate}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="task_table_main_container">
        <TableContainer elevation={0} className="task_table_container">
          <Table size="small" stickyHeader>
            <TableHead className="task_table_head">
              <TableRow>
                <TableCell className="task_table_head_cell check">
                  <Checkbox
                    color="primary"
                    size="small"
                    onChange={handleCheckboxArray}
                    checked={mainCheckbox}
                  ></Checkbox>
                </TableCell>
                {headCells &&
                  headCells.map((x, i) => (
                    <TableCell
                      className="task_table_head_cell"
                      sortDirection={true}
                    >
                      <TableSortLabel
                        active={x.active}
                        direction={x.direction}
                        onClick={(e) => handleSort(i)}
                      >
                        {x.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                <TableCell className="task_table_head_cell">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((x, i) => (
                <TableRow>
                  <TableCell className="task_table_body_cell check">
                    <div className="checkBox_priority_cell">
                      <Checkbox
                        color="primary"
                        size="small"
                        style={{ paddingRight: 0 }}
                        checked={x.isSelected === true ? true : false}
                        onChange={(e) => handleCheckBox(e, i)}
                      ></Checkbox>
                      {x.priority === 1 ? (
                        <PriorityHighIcon
                          fontSize="small"
                          className="priority_icon_high"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="task_table_body_cell ticket">
                    {x.ticketNumber}
                  </TableCell>
                  <TableCell className="task_table_body_cell subject">
                    {x.subject ? sentanceCase(x.subject.substring(0, 35)) : ""}
                  </TableCell>
                  <TableCell className="task_table_body_cell subject">
                    {x.description ? (
                      <Tooltip title={x.description} arrow>
                        <span style={{ cursor: "pointer" }}>
                          {sentanceCase(x.description.substring(0, 50))}
                        </span>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </TableCell>{" "}
                  <TableCell className="task_table_body_cell clinic">
                    {x.clinicName
                      ? sentanceCase(x.clinicName.substring(0, 23))
                      : ""}
                  </TableCell>
                  <TableCell className="task_table_body_cell owner">
                    {x.assignedByName ? sentanceCase(x.assignedByName) : ""}
                  </TableCell>
                  <TableCell className="task_table_body_cell owner">
                    {/* <GroupedDropdown
                      className="owner_update_dropdown"
                      formControlStyle={{ width: "120px" }}
                      selectStyle={{ marginTop: "-7px", fontSize: "14px" }}
                      user={user}
                      defaultValue={{
                        value: x.assignedTo,
                        label: x.assignedToUserName,
                      }}
                      onChange={(value) => ownerUpdate(value, x, i)}
                    /> */}
                    <Dropdown
                      className="owner_update_dropdown"
                      variant="standard"
                      size="small"
                      options={user}
                      value={x.assignedTo}
                      onChange={(e) => ownerUpdate(e, x, i)}
                    />
                  </TableCell>
                  <TableCell className="task_table_body_cell type">
                    <Dropdown
                      className="type_dropdown"
                      variant="standard"
                      size="small"
                      options={workFlowType}
                      value={x.workflowType}
                      onChange={(e) => typeUpdate(e, x, i)}
                    />
                  </TableCell>
                  <TableCell className="task_table_body_cell date">
                    {dateTommddyyy(x.assignedOn)}
                  </TableCell>
                  <TableCell className="task_table_body_cell date">
                    {x.dueDate ? (
                      <Date
                        value={dateTommddyyy(x.dueDate)}
                        onChange={(e) => updateDueDate(e, x, i)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell className="task_table_body_cell status">
                    <Dropdown
                      className="status_dropdown"
                      variant="standard"
                      size="small"
                      options={Status}
                      value={x.status}
                      onChange={(e) => statusUpdate(e, x, i)}
                    />
                  </TableCell>
                  <TableCell className="task_table_body_cell">
                    {" "}
                    <div className="table_icons">
                      <EditIcon
                        color="primary"
                        className="table_editBtn"
                        fontSize="small"
                        onClick={(e) => handleEdit(x)}
                      />
                      <CommentIcon
                        color="primary"
                        className="table_editBtn"
                        fontSize="small"
                        onClick={(e) => handleNotes(x)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {rows.length === 0 && (
            <div className="noDataFound">
              <img src={noDataFound} alt={"NoDataFound"} />
            </div>
          )}
        </TableContainer>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  Tasks: state.Task,
  Tickets: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  taskList: (values) => dispatch(taskList(values)),
  getCache: (values) => dispatch(getCache(values)),
  taskFilter: (values) => dispatch(taskFilter(values)),
  ticketNotes: (values) => dispatch(ticketNotes(values)),
  rowUpdate: (values) => dispatch(taskRowUpdate(values)),
  taskBulkUpdate: (values) => dispatch(taskBulkUpdate(values)),
  ticketList: (values) => dispatch(ticketList(values)),
  typeList: (values) => dispatch(typeList(values)),
});
Tasks.propTypes = {
  taskList: PropTypes.func,
  getCache: PropTypes.func,
  taskFilter: PropTypes.func,
  ticketNotes: PropTypes.func,
  taskRowUpdate: PropTypes.func,
  taskBulkUpdate: PropTypes.func,
  ticketList: PropTypes.func,
  typeList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
