import { ajax } from 'rxjs/ajax'
import {toast} from 'react-hot-toast'
import { catchError } from 'rxjs/operators'
import { of } from 'rxjs'
export function ajaxUtils(params) {
  const token = localStorage.getItem('accessToken') || null
  // const Bearer = 'Bearer '
  const defaultHeader = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  }
  const paramsHeader = params.headers || {}
  const passingHeader = token
    ? { ...defaultHeader, ...paramsHeader }
    : { ...paramsHeader }
  return ajax({ ...params, ...{ headers: passingHeader } }).pipe(
    catchError((error) => {
      if (
        error.status === 401 ||
        error.status === 500 ||
        error.status === 403
      ) {
        toast.error('The connection has timed out. Please Try Again!')
        setTimeout(() => {
          window.location.href = '/login'
        }, 100)
      }
      return of(error)
    }),
  )
}
export function objectToUrl(url, object) {
  const encoded = Object.entries(object)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&')
    .replace(/[a-zA-Z0-9]+[=](null)(&)/g, '')
    .replace(/(&)[a-zA-Z0-9]+[=](null)/g, '')
    .replace(/[a-zA-Z0-9]+[=]([\s]+)?(&)/g, '')
    .replace(/(&)[a-zA-Z0-9]+[=]+([\s]+)?(?![a-zA-Z0-9-])/g, '')
  return `${url}?${encoded}`
}

export function objectToUrlTable(url, object) {
  const encoded = Object.entries(object)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&')
    .replace(/[a-zA-Z0-9]+[=]('')(&)/g, '')
    .replace(/(&)[a-zA-Z0-9]+[=]('')/g, '')
    .replace(/[a-zA-Z0-9]+[=]([\s]+)?(&)/g, '')
    .replace(/(&)[a-zA-Z0-9]+[=]+([\s]+)?(?![a-zA-Z0-9-])/g, '')
  return `${url}?${encoded}`
}
