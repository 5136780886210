import React, { useState, useEffect } from "react";
import { Button, Dialog, Grid, Typography, Zoom } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dropdown from "../Dropdown";
import DobPicker from "../dobPicker";
import Input from "../Input";
import {
  newTicket,
  rowUpdate,
  ticketList,
} from "../../Redux/TicketApi/Ticket.action";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dateToyyymmdd } from "../../HelperFunctions/TimeDateTimestamp";
import toast from "react-hot-toast";
import "./style.css";

function NewTicketDialog(props) {
  const Status = [
    // { name: "Open", value: 5 },
    { name: "Not started", value: 1 },
    { name: "In progress", value: 2 },
    { name: "Completed", value: 3 },
    { name: "Cancelled", value: 4 },
    { name: "Resolved", value: 6 },
  ];

  const Priority = [
    { name: "High", value: 1 },
    { name: "Medium", value: 2 },
    { name: "Low", value: 3 },
  ];

  const [user, setUser] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [values, setValues] = useState({
    subject: null,
    clinic: null,
    type: null,
    from: null,
    fromName: null,
    owner: null,
    ownerName: null,
    dueDate: null,
    status: null,
    priority: null,
    description: null,
    ticketId: null,
  });
  // const [submitted, setSubmitted] = useState(false);
  const [workFlowType, setWorkFlowType] = useState([]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("cache"));
    let clinicList = [];
    let usersList = [];
    cache.clinicDtoList.forEach((x) => {
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });
    cache.userDtoList.forEach((x) => {
      usersList.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    // Sort the owner array alphabetically by name
    usersList.sort((a, b) => a.name.localeCompare(b.name));
    setClinicList(clinicList);
    setUser(usersList);
  }, []);
  
  console.log('props.ticketInfo',props.ticketInfo);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.ticketInfo) {
      props.rowUpdate({
        ticketId: props.ticketInfo.ticketId,
        ticketNumber: null,
        subject: values.subject,
        priority: values.priority,
        currentStatus: values.status,
        currentAssignedTo: values.owner,
        currentWorkflowStepId: null,
        dueDate: values.dueDate
          ? dateToyyymmdd(values.dueDate) + "T12:00:00.000Z"
          : null,
        workflowType: values.type,
        clinicId: values.clinic,
        ticketWatcherCC: null,
        createdBy: values.from,
        description: values.description,
        id: props.ticketInfo.ticketId,
      });
    } else {
      props.newTicket({
        ticketId: null,
        ticketNumber: null,
        subject: values.subject,
        priority: values.priority,
        currentStatus: values.status,
        currentAssignedTo: values.owner,
        currentWorkflowStepId: null,
        dueDate: values.dueDate
          ? dateToyyymmdd(values.dueDate) + "T12:00:00.000Z"
          : null, //+ "T00:00:00.000+00:00",
        workflowType: values.type,
        clinicId: values.clinic,
        ticketWatcherCC: null,
        createdBy: values.from,
        description: values.description,
      });
    }
  };

  const handleClose = () => {
    props.handleClose();
    // setSubmitted(false);
    setValues({
      subject: null,
      clinic: null,
      type: null,
      from: null,
      owner: null,
      dueDate: null,
      status: null,
      priority: null,
      description: null,
    });
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("owner"));
    if (props.Tickets.newTicket) {
      let data = props.Tickets.newTicket.response.data;
      if (data.ticketId) {
        toast.success("Ticket added successfully");
        props.handleClose();
        // setSubmitted(false);
        props.ticketList({
          start: 0,
          limit: 20,
          sortBy: null,
          sortDirection: null,
          ticketDto: {
            ticketId: null,
            ticketNumber: null,
            subject: null,
            priority: null,
            currentStatus: null,
            currentAssignedTo: null,
            currentWorkflowStepId: null,
            dueDate: null,
            clinicId: null,
            createdBy: null,
            workflowType: null,
            loggedUser: user.userId,
          },
        });
        setValues({
          subject: null,
          clinic: null,
          type: null,
          from: null,
          owner: null,
          dueDate: null,
          status: null,
          priority: null,
          description: null,
        });
        props.Tickets.newTicket = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.newTicket]);

  // const validateDescription = (value) => {
  //   if (submitted) {
  //     if (value.length < 5) {
  //       return "Description must be at least 5 characters";
  //     } else if (value.length >= 200) {
  //       return "Description maximum support 200 characters";
  //     }
  //   } else {
  //   }
  //   return "";
  // };

  useEffect(() => {
    if (props.ticketInfo) {
      setValues({
        ...values,
        ticketId: props.ticketInfo.ticketId,
        subject: props.ticketInfo.subject,
        clinic: props.ticketInfo.clinicId,
        type: props.ticketInfo.type,
        from: props.ticketInfo.createdBy,
        fromName: props.ticketInfo.createdByName,
        owner: props.ticketInfo.owner,
        ownerName: props.ticketInfo.ownerName,
        dueDate: props.ticketInfo.dueDate,
        status: props.ticketInfo.status,
        priority: props.ticketInfo.priority,
        description: props.ticketInfo.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticketInfo]);

  useEffect(() => {
    if (props.Tickets.typeList) {
      let response = props.Tickets.typeList.response;
      if (response.status === 200) {
        let data = response.data;
        let typeList = [];
        data.forEach((x) => {
          const name =
            x.workFlow.length > 2
              ? x.workFlow.charAt(0).toUpperCase() +
                x.workFlow.slice(1).toLowerCase()
              : x.workFlow;

          typeList.push({
            name,
            value: x.workflowMasterId,
          });
        });
        setWorkFlowType(typeList);
      }
    }
  }, [props.Tickets.typeList]);

  return (
    <div>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        className="ticket_create_dialog"
        TransitionComponent={Zoom}
        TransitionProps={{
          timeout: 300,
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="ticket_create_container">
            <div className="close_icon">
              <Typography
                style={{
                  fontWeight: "600",
                  paddingBottom: "0.5rem",
                  fontSize: "1.2rem",
                }}
              >
                Ticket Information
              </Typography>
              <CloseIcon onClick={handleClose} />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  required
                  type="text"
                  label="Subject"
                  onChange={(e) => {
                    setValues({ ...values, subject: e.target.value });
                  }}
                  value={values.subject}
                  // validation={(value) =>
                  //   submitted
                  //     ? value
                  //       ? value.length >= 5
                  //         ? value.length > 45
                  //           ? "maximum support 45 characters"
                  //           : ""
                  //         : "Must be 5 characters"
                  //       : "This field is required"
                  //     : ""
                  // }
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  label="Clinic Name"
                  options={clinicList}
                  onChange={(e) => {
                    setValues({ ...values, clinic: e.target.value });
                  }}
                  value={values.clinic}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  label="Type of Service"
                  options={workFlowType}
                  onChange={(e) => {
                    setValues({ ...values, type: e.target.value });
                  }}
                  value={values.type}
                />
              </Grid>
              <Grid item xs={4}>
                {/* <GroupedDropdown
                  formControlStyle={{ width: "155px" }}
                  user={user}
                  inputLabel="From"
                  defaultValue={{
                    value: values.from ? values.from : "",
                    label: values.fromName ? values.fromName : "",
                  }}
                  onChange={(value) => {
                    setValues({ ...values, from: value });
                  }}
                /> */}
                <Dropdown
                  label="From"
                  options={user}
                  onChange={(e) => {
                    setValues({ ...values, from: e.target.value });
                  }}
                  value={values.from}
                />
              </Grid>
              <Grid item xs={4}>
                {/* <GroupedDropdown
                  formControlStyle={{ width: "155px" }}
                  user={user}
                  inputLabel="Ticket Owner"
                  defaultValue={{
                    value: values.owner ? values.owner : "",
                    label: values.ownerName ? values.ownerName : "",
                  }}
                  onChange={(value) => {
                    setValues({ ...values, owner: value });
                  }}
                /> */}
                <Dropdown
                  label="Ticket Owner"
                  options={user}
                  onChange={(e) => {
                    setValues({ ...values, owner: e.target.value });
                  }}
                  value={values.owner}
                />
              </Grid>
              <Grid item xs={4}>
                <DobPicker
                  inputVariant={"standard"}
                  label="Due Date"
                  onChange={(e) => {
                    setValues({ ...values, dueDate: e.target.value });
                  }}
                  value={values.dueDate}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  label="Status"
                  options={Status}
                  onChange={(e) => {
                    setValues({ ...values, status: e.target.value });
                  }}
                  value={values.status}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  label="Priority"
                  options={Priority}
                  onChange={(e) => {
                    setValues({ ...values, priority: e.target.value });
                  }}
                  value={values.priority}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="description" style={{ color: "grey" }}>
                  Description
                </label>
                <textarea
                  className="text_area"
                  name=""
                  id=""
                  cols="60"
                  // rows="1"
                  type="text"
                  onChange={(e) => {
                    setValues({ ...values, description: e.target.value });
                  }}
                  value={values.description}
                  style={{
                    fontSize: "16px",
                    outline: "none",
                    fontFamily: "Inter, sans-serif",
                    maxWidth: "500px",
                    border: "none",
                    borderBottom: "1px solid grey",
                    minHeight: "20px",
                    paddingTop: "0.5rem",
                  }}
                ></textarea>
                {/* {submitted && (
                  <div className="des_error_message">
                    {values.description
                      ? validateDescription(values.description)
                      : ""}
                  </div>
                )} */}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="newTicketBtn">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </div>
            </Grid>
          </div>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Tickets: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  newTicket: (values) => dispatch(newTicket(values)),
  ticketList: (values) => dispatch(ticketList(values)),
  rowUpdate: (values) => dispatch(rowUpdate(values)),
});
NewTicketDialog.propTypes = {
  newTicket: PropTypes.func,
  ticketList: PropTypes.func,
  rowUpdate: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTicketDialog);
