import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import Dropdown from "../../Components/Dropdown";
import DobPicker from "../../Components/dobPicker";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  bulkUpdate,
  rowUpdate,
  ticketFilter,
  ticketList,
  ticketNotes,
  typeList,
  userList,
} from "../../Redux/TicketApi/Ticket.action";
import EditIcon from "@material-ui/icons/Edit";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CommentIcon from "@material-ui/icons/Comment";
import {
  TimestamptoTime,
  dateTommddyyy,
  dateToyyymmdd,
} from "../../HelperFunctions/TimeDateTimestamp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import NewTicketDialog from "../../Components/NewTicketDialog";
import Comments from "../../Components/Comments";
import toast from "react-hot-toast";
import noDataFound from "../../assets/nodata-found.png";
import NewTaskDialog from "../../Components/NewTaskDialog";
import Date from "../../Components/Date/Date";
import "./style.css";

function Tickets(props) {
  const owner = JSON.parse(localStorage.getItem("owner"));
  const [headCells, setHeadCells] = useState([
    {
      active: false,
      direction: "desc",
      label: "Ticket No",
      item: "ticketNumber",
    },
    { active: false, direction: "desc", label: "Subject", item: "subject" },
    {
      active: false,
      direction: "desc",
      label: "Description",
      item: "description",
    },
    { active: false, direction: "desc", label: "Clinic Name", item: "clinic" },
    { active: false, direction: "desc", label: "From", item: "createdByUser" },
    {
      active: false,
      direction: "desc",
      label: "Owner",
      item: "currentAssignedToUser",
    },
    {
      active: false,
      direction: "desc",
      label: "Type",
      item: "workflowTypeMaster",
    },
    { active: false, direction: "desc", label: "Opened", item: "createdOn" },
    { active: false, direction: "desc", label: "Due Date", item: "dueDate" },
    {
      active: false,
      direction: "desc",
      label: "Status",
      item: "currentStatus",
    },
  ]);

  const Status = [
    // { name: "Open", value: 5 },
    { name: "Not started", value: 1 },
    { name: "In progress", value: 2 },
    { name: "Completed", value: 3 },
    { name: "Cancelled", value: 4 },
    { name: "Resolved", value: 6 },
  ];

  const Priority = [
    { name: "High", value: 1 },
    { name: "Medium", value: 2 },
    { name: "Low", value: 3 },
  ];

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [clinicList, setClinicList] = useState([]);
  const [forms, setForms] = useState({
    clinic: null,
    type: null,
    from: null,
    owner: owner.userId,
    dueDate: null,
    status: 2,
    priority: null,
  });
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [user, setUser] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [ticketInfo, setTicketInfo] = useState([]);
  const [openTask, setOpenTask] = useState(false);
  const [checkBoxDto, setCheckBoxDto] = useState([]);
  const [actionForm, setActionForm] = useState({
    owner: null,
    type: null,
    status: null,
  });
  const [mainCheckBox, setMainCheckBox] = useState(false);
  const [editTicket, setEditTicket] = useState(false);
  const [workFlowType, setWorkFlowType] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("owner"));
    let filter = props.Tickets.ticketFilter;
    props.ticketList({
      start: page,
      limit: rowsPerPage,
      sortBy: sortBy,
      sortDirection: sortDirection,
      ticketDto: {
        ticketId: null,
        ticketNumber: null,
        subject: null,
        priority: filter.priority ? filter.priority : null,
        // currentStatus: filter.status ? filter.status : 5,
        // currentAssignedTo: filter.owner ? filter.owner : user.userId,
        currentStatus:
          filter.status === 0 ? 2 : filter.status !== 0 ? filter.status : null,
        currentAssignedTo:
          filter.owner === 0
            ? user.userId
            : filter.owner !== 0
            ? filter.owner
            : null,
        currentWorkflowStepId: null,
        dueDate: filter.dueDate
          ? dateToyyymmdd(filter.dueDate) + "T12:00:00.000Z"
          : null,
        clinicId: filter.clinic ? filter.clinic : null,
        createdBy: filter.from ? filter.from : null,
        workflowType: filter.type ? filter.type : null,
        loggedUser: user.userId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    sortBy,
    sortDirection,
    props.Tickets.ticketFilter,
    props.Tickets.rowUpdate,
  ]);

  useEffect(() => {
    if (props.Tickets.ticketTable) {
      let data = props.Tickets.ticketTable.response.data;
      setRows(data.result.ticketDtoList);
      setCount(data.totalRecords);
    }
  }, [props.Tickets.ticketTable]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sentenceCase = (txt) => {
    const words = txt.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length === 0) {
        return "";
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return capitalizedWords.join(" ");
  };

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("cache"));
    let clinicList = [];
    let usersList = [];
    cache.clinicDtoList.forEach((x) => {
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });
    cache.userDtoList.forEach((x) => {
      usersList.push({
        name: x.fullName ? sentenceCase(x.fullName) : "",
        value: x.userId,
      });
    });
    // Sort the owner array alphabetically by name
    usersList.sort((a, b) => a.name.localeCompare(b.name));
    setClinicList(clinicList);
    setUser(usersList);
  }, []);

  const handleSort = (index) => {
    let tableHead = headCells;
    tableHead.forEach((x, i) => {
      if (i === index) {
        tableHead[i].active = true;
        tableHead[i].direction =
          tableHead[i].direction === "asc" ? "desc" : "asc";
        setSortBy(tableHead[i].item);
        setSortDirection(tableHead[i].direction === "asc" ? "desc" : "asc");
      } else {
        tableHead[i].active = false;
        tableHead[i].direction = "desc";
      }
    });
    setHeadCells([...tableHead]);
  };

  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  const handleNewTicket = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const notesClose = () => {
    setOpenComments(false);
  };
  const handleComments = (x) => {
    localStorage.setItem("ticketId", x.ticketId);
    setOpenComments(true);
    props.ticketNotes({
      start: 0,
      limit: 10,
      sortBy: null,
      sortDirection: null,
      ticketCommentDto: {
        ticketCommentId: null,
        comment: null,
        ticketId: x.ticketId,
        taskId: null,
      },
    });
    setTicketInfo({
      name: x.createdByName,
      number: x.ticketNumber,
      date: x.createdOn
        ? dateTommddyyy(x.createdOn) + " " + TimestamptoTime(x.createdOn)
        : "",
      ticketId: x.ticketId,
      subject: x.subject,
      clinicId: x.clinicId,
      clinicName: x.clinicName,
      description: x.description,
    });
  };

  const updateStatus = (e, x, i) => {
    let row = rows;
    row[i].currentStatus = e.target.value;
    setRows([...row]);
    props.rowUpdate({
      ticketId: x.ticketId,
      ticketNumber: null,
      subject: null,
      priority: null,
      currentStatus: e.target.value,
      currentAssignedTo: null,
      currentWorkflowStepId: null,
      dueDate: null,
      workflowType: null,
      clinicId: null,
      ticketWatcherCC: null,
      createdBy: null,
      description: null,
      id: x.ticketId,
    });
  };

  const updateOwner = (e, x, i) => {
    let row = rows;
    row[i].currentAssignedTo = e.target.value;
    setRows([...row]);
    props.rowUpdate({
      ticketId: x.ticketId,
      ticketNumber: null,
      subject: null,
      priority: null,
      currentStatus: null,
      currentAssignedTo: e.target.value,
      currentWorkflowStepId: null,
      dueDate: null,
      workflowType: null,
      clinicId: null,
      ticketWatcherCC: null,
      createdBy: null,
      description: null,
      id: x.ticketId,
    });
  };

  const updateType = (e, x, i) => {
    let row = rows;
    row[i].workflowType = e.target.value;
    setRows([...row]);
    props.rowUpdate({
      ticketId: x.ticketId,
      ticketNumber: null,
      subject: null,
      priority: null,
      currentStatus: null,
      currentAssignedTo: null,
      currentWorkflowStepId: null,
      dueDate: null,
      workflowType: e.target.value,
      clinicId: null,
      ticketWatcherCC: null,
      createdBy: null,
      description: null,
      id: x.ticketId,
    });
  };

  const updateDueDate = (e, x, i) => {
    if (dateTommddyyy(e.target.value)) {
      props.rowUpdate({
        ticketId: x.ticketId,
        ticketNumber: null,
        subject: null,
        priority: null,
        currentStatus: null,
        currentAssignedTo: null,
        currentWorkflowStepId: null,
        dueDate: dateToyyymmdd(e.target.value) + "T12:00:00.000Z",
        workflowType: null,
        clinicId: null,
        ticketWatcherCC: null,
        createdBy: null,
        description: null,
        id: x.ticketId,
      });
    }
  };

  useEffect(() => {
    if (props.Tickets.rowUpdate) {
      let response = props.Tickets.rowUpdate.response;
      if (response.status === 200) {
        toast.success("Updation success");
        props.Tickets.rowUpdate = null;
        setEditTicket(false);
      } else {
        toast.error("Couldn't update");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.rowUpdate]);

  const handleNewTask = (x) => {
    setOpenTask(true);
    setTicketInfo({
      name: x.createdByName,
      number: x.ticketNumber,
      date: x.createdOn
        ? dateTommddyyy(x.createdOn) + " " + TimestamptoTime(x.createdOn)
        : "",
      ticketId: x.ticketId,
      subject: x.subject,
      clinicId: x.clinicId,
      clinicName: x.clinicName,
    });
  };

  const taskClose = () => {
    setOpenTask(false);
  };

  const handleCheckbox = (e, i) => {
    let row = rows;
    row[i] = { ...row[i], isSelected: e.target.checked };
    setRows([...row]);
    let a = [];
    row.forEach((x) => {
      if (x.isSelected === true) {
        a.push({
          ticketId: x.ticketId,
          owner: x.currentAssignedTo,
          type: x.workflowType,
          status: x.currentStatus,
        });
      }
    });
    setCheckBoxDto(a);
  };

  const handleBulkUpdate = () => {
    let row = rows;
    row.forEach((x) => {
      if (x.isSelected === true) {
        // console.log("action form owner", actionForm.owner);
        // console.log("x owner", x.currentAssignedTo);
        x.currentAssignedTo = actionForm.owner
          ? actionForm.owner
          : x.currentAssignedTo;
        x.workflowType = actionForm.type ? actionForm.type : x.workflowType;
        x.currentStatus = actionForm.status
          ? actionForm.status
          : x.currentStatus;
        setRows([...row]);
      }
    });
    let a = [];
    checkBoxDto.forEach((x) => {
      a.push({
        ticketId: x.ticketId,
        currentAssignedTo: actionForm.owner,
        workflowType: actionForm.type,
        currentStatus: actionForm.status,
      });
    });
    if (actionForm.owner || actionForm.type || actionForm.status) {
      props.bulkUpdate(a);
    }
  };

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("owner"));
    if (props.Tickets.bulkUpdate) {
      let response = props.Tickets.bulkUpdate.response;
      if (response.status === 200) {
        toast.success("Updation success");
        props.Tickets.bulkUpdate = null;
        setCheckBoxDto("");
        setActionForm({ owner: null, type: null, status: null });
        let row = rows;
        row.forEach((x) => {
          if (x.isSelected === true) {
            x.isSelected = false;
            setRows([...row]);
          }
        });
        setMainCheckBox(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.bulkUpdate]);

  const handleCheckboxArray = (e) => {
    setMainCheckBox(e.target.checked);
    let a = [];
    rows.forEach((x) => {
      a.push({
        ...x,
        isSelected: e.target.checked,
      });
    });
    setRows([...a]);
    setCheckBoxDto(a);
  };

  const handleEdit = (x) => {
    setEditTicket(true);
    setTicketInfo({
      ticketId: x.ticketId,
      subject: x.subject,
      clinicId: x.clinicId,
      type: x.workflowType,
      createdBy: x.createdBy,
      createdByName: x.createdByName,
      owner: x.currentAssignedTo,
      ownerName: x.currentAssignedToName,
      dueDate: x.dueDate,
      status: x.currentStatus,
      priority: x.priority,
      description: x.description,
    });
  };

  const handleCloseEdit = () => {
    setEditTicket(false);
  };

  useEffect(() => {
    props.typeList({
      start: 0,
      limit: 10,
      sortBy: null,
      sortDirection: null,
      workflowDto: {
        workflowMasterId: null,
        workFlow: null,
        type: null,
        auditFieldInfo: {
          createdBy: null,
          updatedBy: null,
          createdOn: null,
          updatedOn: null,
          isActive: true,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.Tickets.typeList) {
      let response = props.Tickets.typeList.response;
      if (response.status === 200) {
        let data = response.data;
        let typeList = [];
        data.forEach((x) => {
          const name =
            x.workFlow.length > 2
              ? x.workFlow.charAt(0).toUpperCase() +
                x.workFlow.slice(1).toLowerCase()
              : x.workFlow;

          typeList.push({
            name,
            value: x.workflowMasterId,
          });
        });
        setWorkFlowType(typeList);
      }
    }
  }, [props.Tickets.typeList]);

  return (
    <div className="ticket_main_container">
      <div className="add_new_ticket">
        <AddCircleIcon
          onClick={handleNewTicket}
          style={{
            fontSize: "4rem",
            backgroundColor: "white",
            borderRadius: "50%",
          }}
          color="primary"
        />
        <NewTicketDialog open={openDialog} handleClose={handleClose} />
      </div>
      <Comments
        open={openComments}
        handleClose={notesClose}
        ticketInfo={ticketInfo}
      />
      <NewTaskDialog
        open={openTask}
        handleClose={taskClose}
        ticketInfo={ticketInfo}
      />
      <NewTicketDialog
        open={editTicket}
        handleClose={handleCloseEdit}
        ticketInfo={ticketInfo}
      />
      <div className="ticket_filter_container">
        <div className="ticket_pagination_container">
          <TablePagination
            className="ticket_pagination"
            component="div"
            rowsPerPageOptions={[20, 25, 50, 100]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div className="ticket_filters">
          <div className="ticket_filters_dropdown">
            <Dropdown
              variant="standard"
              label="Clinic"
              options={[{ name: "All Clinics", value: null }, ...clinicList]}
              onChange={(e) => {
                setForms({ ...forms, clinic: e.target.value });
              }}
              value={forms.clinic}
            />
          </div>
          <div className="ticket_filters_dropdown">
            <Dropdown
              variant="standard"
              label="Type"
              options={[{ name: "All Types", value: null }, ...workFlowType]}
              onChange={(e) => {
                setForms({ ...forms, type: e.target.value });
              }}
              value={forms.type}
            />
          </div>
          <div className="ticket_filters_dropdown">
            {/* <GroupedDropdown
              user={user}
              inputLabel="From"
              onChange={(value) => {
                setForms({ ...forms, from: value });
              }}
            /> */}
            <Dropdown
              variant="standard"
              label="From"
              options={[{ name: "All Users", value: null }, ...user]}
              onChange={(e) => {
                setForms({ ...forms, from: e.target.value });
              }}
              value={forms.from}
            />
          </div>
          <div className="ticket_filters_dropdown">
            {/* <GroupedDropdown
              user={user}
              inputLabel="Owner"
              onChange={(value) => {
                setForms({ ...forms, owner: value });
              }}
              defaultValue={{ value: owner.userId, label: owner.userName }}
            /> */}
            <Dropdown
              variant="standard"
              label="Owner"
              options={[{ name: "All Users", value: null }, ...user]}
              onChange={(e) => {
                setForms({ ...forms, owner: e.target.value });
              }}
              value={forms.owner}
            />
          </div>
          <div className="ticket_filters_date">
            <DobPicker
              label="Due Date"
              inputVariant={"standard"}
              value={forms.dueDate}
              onChange={(e) => setForms({ ...forms, dueDate: e.target.value })}
            />
          </div>
          <div className="ticket_filters_dropdown">
            <Dropdown
              variant="standard"
              label="Status"
              options={[{ name: "All", value: null }, ...Status]}
              onChange={(e) => {
                setForms({ ...forms, status: e.target.value });
              }}
              value={forms.status}
            />
          </div>
          <div className="ticket_filters_dropdown">
            <Dropdown
              variant="standard"
              label="Priority"
              options={[{ name: "All", value: null }, ...Priority]}
              onChange={(e) => {
                setForms({ ...forms, priority: e.target.value });
              }}
              value={forms.priority}
            />
          </div>

          <div className="ticket_filters_btn">
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.ticketFilter(forms)}
            >
              FILTER
            </Button>
          </div>
          <div className="bulk_update">
            <div className="bulk_update_dropdown">
              {/* <GroupedDropdown
                formControlStyle={{ width: "80px" }}
                user={user}
                inputLabel="Owner"
                onChange={(value) =>
                  setActionForm({ ...actionForm, owner: value })
                }
              /> */}
              <Dropdown
                variant="standard"
                label="Owner"
                options={[{ name: "All Users", value: null }, ...user]}
                onChange={(e) =>
                  setActionForm({ ...actionForm, owner: e.target.value })
                }
                value={actionForm.owner ? actionForm.owner : ""}
              />
            </div>
            <div className="bulk_update_dropdown">
              <Dropdown
                variant="standard"
                label="Type"
                options={workFlowType}
                value={actionForm.type ? actionForm.type : ""}
                onChange={(e) =>
                  setActionForm({ ...actionForm, type: e.target.value })
                }
              />
            </div>
            <div className="bulk_update_dropdown">
              <Dropdown
                variant="standard"
                label="Status"
                options={Status}
                value={actionForm.status ? actionForm.status : ""}
                onChange={(e) =>
                  setActionForm({ ...actionForm, status: e.target.value })
                }
              />
            </div>
            <div className="bulk_update_btn">
              <Button
                variant="contained"
                color="primary"
                disabled={checkBoxDto.length > 0 ? false : true}
                onClick={handleBulkUpdate}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="ticket_table_main_container">
        <TableContainer elevation={0} className="ticket_table_container">
          <Table size="small" stickyHeader>
            <TableHead className="ticket_table_head">
              <TableRow>
                <TableCell className="ticket_table_head_cell check">
                  <Checkbox
                    color="primary"
                    size="small"
                    onChange={handleCheckboxArray}
                    checked={mainCheckBox}
                  ></Checkbox>
                </TableCell>
                {headCells &&
                  headCells.map((x, i) => (
                    <TableCell
                      className="ticket_table_head_cell"
                      sortDirection={true}
                    >
                      <TableSortLabel
                        active={x.active}
                        direction={x.direction}
                        onClick={(e) => handleSort(i)}
                      >
                        {x.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                <TableCell className="ticket_table_head_cell">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((x, i) => (
                <TableRow>
                  <TableCell className="ticket_table_body_cell check">
                    <div className="checkBox_priority_cell">
                      <Checkbox
                        color="primary"
                        size="small"
                        style={{ paddingRight: 0 }}
                        checked={x.isSelected === true ? true : false}
                        onChange={(e) => handleCheckbox(e, i)}
                      ></Checkbox>
                      {x.priority === 1 ? (
                        <PriorityHighIcon
                          fontSize="small"
                          className="priority_icon_high"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="ticket_table_body_cell ticket">
                    {x.ticketNumber}
                  </TableCell>
                  <TableCell className="ticket_table_body_cell subject">
                    {x.subject ? sentanceCase(x.subject.substring(0, 35)) : ""}
                  </TableCell>
                  <TableCell className="ticket_table_body_cell subject">
                    {/* {x.description
                      ? sentanceCase(x.description.substring(0, 35))
                      : ""} */}
                    {x.description ? (
                      <Tooltip title={x.description} arrow>
                        <span style={{ cursor: "pointer" }}>
                          {sentanceCase(x.description.substring(0, 50))}
                          {/* {sentanceCase(x.description)} */}
                        </span>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell className="ticket_table_body_cell clinic">
                    {x.clinicName
                      ? sentanceCase(x.clinicName.substring(0, 23))
                      : ""}
                  </TableCell>
                  <TableCell className="ticket_table_body_cell owner">
                    {" "}
                    {x.createdByName ? sentanceCase(x.createdByName) : ""}
                  </TableCell>
                  <TableCell className="ticket_table_body_cell owner">
                    {" "}
                    {/* <GroupedDropdown
                      className="owner_update_dropdown"
                      formControlStyle={{ width: "120px" }}
                      selectStyle={{ marginTop: "-7px", fontSize: "14px" }}
                      user={user}
                      defaultValue={{
                        value: x.currentAssignedTo,
                        label: x.currentAssignedToName,
                      }}
                      onChange={(value) => updateOwner(value, x, i)}
                    /> */}
                    <Dropdown
                      className="owner_update_dropdown"
                      variant="standard"
                      size="small"
                      options={user}
                      value={x.currentAssignedTo}
                      onChange={(e) => updateOwner(e, x, i)}
                    />
                  </TableCell>
                  <TableCell className="ticket_table_body_cell type">
                    <Dropdown
                      className="type_update_dropdown"
                      variant="standard"
                      size="small"
                      options={workFlowType}
                      value={x.workflowType}
                      onChange={(e) => updateType(e, x, i)}
                    />
                  </TableCell>
                  <TableCell className="ticket_table_body_cell date">
                    {" "}
                    {dateTommddyyy(x.createdOn)}
                  </TableCell>
                  <TableCell className="ticket_table_body_cell date">
                    {x.dueDate ? (
                      <Date
                        value={dateTommddyyy(x.dueDate)}
                        onChange={(e) => updateDueDate(e, x, i)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell className="ticket_table_body_cell status">
                    {" "}
                    <Dropdown
                      className="status_update_dropdown"
                      variant="standard"
                      size="small"
                      options={Status}
                      value={x.currentStatus}
                      onChange={(e) => updateStatus(e, x, i)}
                    />
                  </TableCell>
                  <TableCell className="ticket_table_body_cell">
                    <div className="table_icons">
                      <EditIcon
                        color="primary"
                        className="table_editBtn"
                        fontSize="small"
                        onClick={(e) => handleEdit(x)}
                      />
                      <CommentIcon
                        color="primary"
                        className="table_editBtn"
                        fontSize="small"
                        onClick={(e) => handleComments(x)}
                      />
                      <PostAddIcon
                        color="primary"
                        className="table_editBtn"
                        fontSize="small"
                        onClick={(e) => handleNewTask(x)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {rows.length === 0 && (
            <div className="noDataFound">
              <img src={noDataFound} alt={"NoDataFound"} />
            </div>
          )}
        </TableContainer>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Tickets: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  ticketList: (values) => dispatch(ticketList(values)),
  userList: (values) => dispatch(userList(values)),
  ticketFilter: (values) => dispatch(ticketFilter(values)),
  typeList: (values) => dispatch(typeList(values)),
  ticketNotes: (values) => dispatch(ticketNotes(values)),
  rowUpdate: (values) => dispatch(rowUpdate(values)),
  bulkUpdate: (values) => dispatch(bulkUpdate(values)),
});
Tickets.propTypes = {
  ticketList: PropTypes.func,
  userList: PropTypes.func,
  ticketFilter: PropTypes.func,
  typeList: PropTypes.func,
  ticketNotes: PropTypes.func,
  rowUpdate: PropTypes.func,
  bulkUpdate: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
