import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase, Popover, Toolbar, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ticketList } from "../../Redux/TicketApi/Ticket.action";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { taskList } from "../../Redux/TaskApis/Task.action";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "2rem !important",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
  root: {
    flexGrow: 1,
  },
  search: {
    border: "1px solid grey",
    position: "relative",
    borderRadius: "15px",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "grey",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    color: "black",
    padding: "6px 0px 4px 0px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

function Header(props) {
  const classes = useStyles();
  const history = useHistory();

  const [newMenuName, setNewMenuName] = useState("tickets");
  const [openedPopover, setOpenedPopover] = useState(false);
  const [searchTicket, setSearchTicket] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [summaryCount, setSummaryCount] = useState("");
  const popoverAnchor = useRef(null);

  const redirectToMenuItems = (menuName) => {
    localStorage.setItem("currentTab", menuName);
    setSearchInputValue(""); // Reset the search input value
    setSearchTicket([]); // Reset the search results
    if (menuName === "tickets") {
      history.push("/tickets");
    }
    if (menuName === "tasks") {
      history.push("/tasks");
    }
  };

  useEffect(() => {
    let value = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    setNewMenuName(value);
  }, [newMenuName]);

  const searchPatient = (e) => {
    const user = JSON.parse(localStorage.getItem("owner"));
    if (newMenuName === "tickets") {
      setSearchInputValue(e.target.value); // Update the search input value
      if (e.target.value.length > 2) {
        props.ticketList({
          start: 0,
          limit: 20,
          sortBy: null,
          sortDirection: null,
          ticketDto: {
            ticketId: null,
            ticketNumber: null,
            subject: e.target.value,
            priority: null,
            currentStatus: null,
            currentAssignedTo: null,
            currentWorkflowStepId: null,
            dueDate: null,
            clinicId: null,
            createdBy: null,
            workflowType: null,
            loggedUser: user.userId,
          },
        });
      }
      if (e.target.value.length === 0) {
        props.ticketList({
          start: 0,
          limit: 20,
          sortBy: null,
          sortDirection: null,
          ticketDto: {
            ticketId: null,
            ticketNumber: null,
            subject: null,
            priority: null,
            currentStatus: null,
            currentAssignedTo: null,
            currentWorkflowStepId: null,
            dueDate: null,
            clinicId: null,
            createdBy: null,
            workflowType: null,
            loggedUser: user.userId,
          },
        });
      }
    } else {
      if (e.target.value.length > 2) {
        props.taskList({
          start: 0,
          limit: 20,
          sortBy: null,
          sortDirection: null,
          ticketTaskDto: {
            ticketTaskId: null,
            ticketId: null,
            workflowStepId: null,
            assignedBy: null,
            assignedToRole: null,
            assignedToUser: null,
            assignedOn: null,
            dueDate: null,
            status: null,
            reminderOn: null,
            clinicId: null,
            workflowType: null,
            priority: null,
            subject: e.target.value,
            loggedUser: user.userId,
          },
        });
      }
      if (e.target.value.length === 0) {
        props.taskList({
          start: 0,
          limit: 20,
          sortBy: null,
          sortDirection: null,
          ticketTaskDto: {
            ticketTaskId: null,
            ticketId: null,
            workflowStepId: null,
            assignedBy: null,
            assignedToRole: null,
            assignedToUser: null,
            assignedOn: null,
            dueDate: null,
            status: null,
            reminderOn: null,
            clinicId: null,
            workflowType: null,
            priority: null,
            loggedUser: user.userId,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (newMenuName === "tickets") {
      if (props.Tickets.ticketTable) {
        let data = props.Tickets.ticketTable.response.data;
        let tickets = data.result.ticketDtoList.map((x) => {
          return {
            ticket: x.ticketNumber,
            id: x.ticketId,
            subject: x.subject,
            // + " - " + x.ticketNumber
          };
        });
        setSearchTicket(tickets);
        setSummaryCount(data.result);
      }
    } else {
      if (props.Tasks.taskTable) {
        let data = props.Tasks.taskTable.response.data;
        let tasks = data.result.ticketTaskDtoList.map((x) => {
          return {
            ticket: x.ticketNumber,
            id: x.ticketTaskId,
            subject: x.subject,
            // + " - " + x.ticketNumber
          };
        });
        setSearchTicket(tasks);
        setSummaryCount(data.result);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.ticketTable, props.Tasks.taskTable]);

  const searchSelect = (i, y) => {
    const user = JSON.parse(localStorage.getItem("owner"));
    if (newMenuName === "tickets") {
      if (y) {
        props.ticketList({
          start: 0,
          limit: 20,
          sortBy: null,
          sortDirection: null,
          ticketDto: {
            ticketId: y.id,
            ticketNumber: null,
            subject: null,
            priority: null,
            currentStatus: null,
            currentAssignedTo: null,
            currentWorkflowStepId: null,
            dueDate: null,
            clinicId: null,
            createdBy: null,
            workflowType: null,
            loggedUser: user.userId,
          },
        });
      }
    } else if (y) {
      props.taskList({
        start: 0,
        limit: 20,
        sortBy: null,
        sortDirection: null,
        ticketTaskDto: {
          ticketTaskId: y.id,
          ticketId: null,
          workflowStepId: null,
          assignedBy: null,
          assignedToRole: null,
          assignedToUser: null,
          assignedOn: null,
          dueDate: null,
          status: null,
          reminderOn: null,
          clinicId: null,
          workflowType: null,
          priority: null,
          loggedUser: user.userId,
        },
      });
    }
  };

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const logOutUser = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className="header_main_container">
      <div className="header_left">
        <ul>
          <li
            className={newMenuName === "tickets" ? "selected" : null}
            onClick={() => {
              redirectToMenuItems("tickets");
              setNewMenuName("");
            }}
          >
            Tickets
          </li>
          <li
            className={newMenuName === "tasks" ? "selected" : null}
            onClick={() => {
              redirectToMenuItems("tasks");
              setNewMenuName("");
            }}
          >
            Tasks
          </li>
        </ul>
      </div>
      <div className="header_right">
        <div className="Header_section">
          <ul>
            <li>In progress {summaryCount.open ? summaryCount.open : 0}</li>
            <li>
              Priority {summaryCount.priority ? summaryCount.priority : 0}{" "}
            </li>
            <li>Overdue {summaryCount.overDue ? summaryCount.overDue : 0} </li>
          </ul>
        </div>

        <div className="search_box">
          <div className={classes.root}>
            <Toolbar>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <div>
                  <Autocomplete
                    options={searchTicket}
                    onChange={(e, y) => searchSelect(e, y)}
                    getOptionLabel={(option) => option.subject}
                    style={{ width: 350 }}
                    value={
                      searchTicket.find(
                        (item) => item.subject === searchInputValue
                      ) || null
                    }
                    renderInput={(params) => {
                      const { InputLabelProps, InputProps, ...rest } = params;
                      return (
                        <InputBase
                          placeholder="Search…"
                          onChange={searchPatient}
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          {...params.InputProps}
                          {...rest}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </Toolbar>
          </div>
        </div>

        <div
          className="popover_container"
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
          onClick={() => setOpenedPopover(true)}
        >
          <div className="clinic_profile_circle" ref={popoverAnchor}>
            T
          </div>
          <KeyboardArrowDownIcon color="primary" style={{ marginTop: "8px" }} />
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.popoverContent,
            }}
            open={openedPopover}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              onMouseEnter: popoverEnter,
              onMouseLeave: popoverLeave,
            }}
          >
            <div className="clinicHeader__popover">
              <div className="clinicHeader__popover--name">Ticket Tool</div>
              <div className="clinicHeader__popover--list">
                <div className="logout" onClick={() => logOutUser()}>
                  Logout
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Tickets: state.Ticket,
  Tasks: state.Task,
});

const mapDispatchToProps = (dispatch) => ({
  ticketList: (values) => dispatch(ticketList(values)),
  taskList: (values) => dispatch(taskList(values)),
});
Header.propTypes = {
  ticketList: PropTypes.func,
  taskList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
