import { ofType } from "redux-observable";
import { Apis } from "../APIs";
import { map, switchMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "../../Utils";
import { objectToUrl } from "../../Utils/ajax-utils";
import {
  LOGIN,
  TICKET_LIST,
  LOGIN_INFO,
  CACHE_LIST,
  TYPE_LIST,
  USER_LIST,
  ROW_UPDATE,
  BULK_UPDATE,
  SEARCH_PATIENT,
  NEW_TICKET,
  TICKET_NOTES,
  NEW_TICKET_NOTES,
  FILE_UPLOAD,
  FILE_DOWNLOAD,
} from "./Ticket.types";
import {
  loginResponse,
  ticketListResponse,
  loginInfoResponse,
  getCacheResponse,
  typeListResponse,
  userListResponse,
  rowUpdateResponse,
  bulkUpdateResponse,
  searchPatientResponse,
  newTicketResponse,
  ticketNotesResponse,
  newTicketNotesRes,
  fileUploadResponse,
  fileDownloadResponse,
} from "./Ticket.action";
import environment from "../../environments/environment";

const { BaseUrl } = environment;
// const { baseUrl2 } = environment;
const TicketEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */

TicketEpic.loginApi = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          "application-id": 11,
          "Content-Type": "application/json",
        },
        url: `${BaseUrl}/${Apis.loginApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(map((response) => loginResponse(response)))
    )
  );

TicketEpic.ticketListApi = (action$) =>
  action$.pipe(
    ofType(TICKET_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/${Apis.ticketListApi}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(map((response) => ticketListResponse(response)))
    )
  );
TicketEpic.newTicketApi = (action$) =>
  action$.pipe(
    ofType(NEW_TICKET),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/${Apis.newTicket}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(map((response) => newTicketResponse(response)))
    )
  );
TicketEpic.ticketNotesApi = (action$) =>
  action$.pipe(
    ofType(TICKET_NOTES),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/ticketing-tool/v1/ticket/comment/list/all`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(map((response) => ticketNotesResponse(response)))
    )
  );
TicketEpic.loginInfo = (action$) =>
  action$.pipe(
    ofType(LOGIN_INFO),
    switchMap((action) =>
      UtilsAjax({
        // headers: {
        //   "application-id": 6,
        //   "Content-Type": "application/json",
        // },
        url: `${BaseUrl}/trillium-internal-service/v1/common/priorAuth/cache?applicationId=11`,
        method: "GET",
      }).pipe(
        map(
          (response) => loginInfoResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
TicketEpic.getCache = (action$) =>
  action$.pipe(
    ofType(CACHE_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/${Apis.cacheListApi}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getCacheResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
TicketEpic.typeList = (action$) =>
  action$.pipe(
    ofType(TYPE_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/ticketing-tool/api/roles/search`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => typeListResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
TicketEpic.userList = (action$) =>
  action$.pipe(
    ofType(USER_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/trillium-internal-service/v1/issuetracker/users/list/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => userListResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

TicketEpic.rowUpdate = (action$) =>
  action$.pipe(
    ofType(ROW_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/${Apis.newTicket}${action.payload.id}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(map((response) => rowUpdateResponse(response)))
    )
  );

TicketEpic.bulkUpdate = (action$) =>
  action$.pipe(
    ofType(BULK_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/${Apis.newTicket}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(map((response) => bulkUpdateResponse(response)))
    )
  );
TicketEpic.searchPatientApi = (action$) =>
  action$.pipe(
    ofType(SEARCH_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${BaseUrl}/trillium-clinic-service/v1/patient/search`,
          action.payload
        ),
        method: "GET",
      }).pipe(
        map(
          (response) => searchPatientResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

TicketEpic.newTicketNotes = (action$) =>
  action$.pipe(
    ofType(NEW_TICKET_NOTES),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/ticketing-tool/v1/ticket/comment/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(map((response) => newTicketNotesRes(response)))
    )
  );

TicketEpic.fileUpload = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: { enctype: "multipart/form-data" },
        url: `${BaseUrl}/ticketing-tool/v1/ticket/comment/upload?ticketId=${action.payload.ticketId}&taskId=${action.payload.taskId}&createdUserId=${action.payload.createdUserId}`,
        method: "POST",
        body: action.payload.data,
      }).pipe(map((response) => fileUploadResponse(response)))
    )
  );

TicketEpic.fileDownload = (action$) =>
  action$.pipe(
    ofType(FILE_DOWNLOAD),
    switchMap((action) =>
      UtilsAjax({
        url: `${BaseUrl}/ticketing-tool/v1/ticket/comment/download/${action.payload}`,
        method: "POST",
        responseType: "blob",
        // body: JSON.stringify(action.payload),
      }).pipe(map((response) => fileDownloadResponse(response)))
    )
  );

export default TicketEpic;
