import React, { useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  date: {
    margin: "0",
    width: "110px",
    minHeight: "30px",
    color: "#2A2F45",
    fontWeight: "500",
  },
}));

const DateField = styled(KeyboardDatePicker)({
  "& .PickerWithState": {
    "&: Mui-focused": {
      border: "none",
    },
  },
  "label + &": {
    marginTop: "1.3rem",
  },
  "& fieldset": {
    top: "-5px",
  },
  "#date-of-birth": {
    borderBottom: "1px solid red !important",
  },
  // Hide the calendar button
  "& .MuiIconButton-root": {
    display: "none",
  },
});

export default function Datepicker(props) {
  const classes = useStyles();
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [value, setValues] = useState(props.value ? props.value : null);

  const handleFocus = () => {
    setDatePickerOpen(true);
  };
  const handleClose = () => {
    setDatePickerOpen(false);
  };

  const handleInput = (date) => {
    setValues(date);
    setDatePickerOpen(false); // Close the date picker
    if (props.onChange) {
      const target = { type: "date", checked: null, value: date };
      props.onChange({ target });
    }
  };

  return (
    <FormControl className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateField
          style={{ width: "87px", marginTop: "-10px" }}
          tabIndex={-1}
          disableToolbar
          error={props.error}
          label={props.label}
          className={classes.date}
          variant="inline"
          margin="normal"
          id="date-of-birth"
          format="MM/dd/yyyy"
          placeholder="mm/dd/yyyy"
          onClick={handleFocus}
          onClose={handleClose}
          open={isDatePickerOpen}
          onChange={handleInput}
          value={value}
          autoOk={true}
          inputProps={{
            style: { fontSize: "15px", paddingBottom: "2px", marginTop: "6px" },
          }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}
