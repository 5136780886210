import {
  LOGIN,
  LOGIN_RES,
  TICKET_LIST,
  TICKET_LIST_RES,
  LOGIN_INFO,
  LOGIN_INFO_RES,
  CACHE_LIST,
  CACHE_LIST_RES,
  TYPE_LIST,
  TYPE_LIST_RES,
  USER_LIST,
  USER_LIST_RES,
  ROW_UPDATE,
  ROW_UPDATE_RES,
  BULK_UPDATE,
  BULK_UPDATE_RES,
  SEARCH_PATIENT,
  SEARCH_PATIENT_RES,
  TICKET_FILTER,
  NEW_TICKET,
  NEW_TICKET_RES,
  TICKET_NOTES,
  TICKET_NOTES_RES,
  NEW_TICKET_NOTES,
  NEW_TICKET_NOTES_RES,
  FILE_UPLOAD,
  FILE_UPLOAD_RES,
  FILE_DOWNLOAD,
  FILE_DOWNLOAD_RES,
} from "./Ticket.types";

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author 

 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from login.type.js,
  payload: object - contains params,
})
 */

/**
 * clinicSave-Action w.r.t CLINIC_SAVE(type) in the redux
 * @description - Action used to call the clinic save API
 * @param logData
 * @returns {{payload: *, type: string}}
 */

/**
 * clinicSaveResponse - Action w.r.t CLINIC_SAVE_RES(type) in the redux
 * @description - Action used to get the response of clinic save API
 * @param user
 * @returns {{payload: *, type: string}}
 */

export const login = (data) => ({
  type: LOGIN,
  payload: data,
});
export const loginResponse = (res) => ({
  type: LOGIN_RES,
  payload: res,
});
export const ticketList = (data) => ({
  type: TICKET_LIST,
  payload: data,
});
export const ticketListResponse = (data) => ({
  type: TICKET_LIST_RES,
  payload: data,
});
export const loginInfo = (data) => ({
  type: LOGIN_INFO,
  payload: data,
});
export const loginInfoResponse = (data) => ({
  type: LOGIN_INFO_RES,
  payload: data,
});
export const getCache = (res) => ({
  type: CACHE_LIST,
  payload: res,
});
export const getCacheResponse = (res) => ({
  type: CACHE_LIST_RES,
  payload: res,
});
export const typeList = (res) => ({
  type: TYPE_LIST,
  payload: res,
});
export const typeListResponse = (res) => ({
  type: TYPE_LIST_RES,
  payload: res,
});
export const userList = (res) => ({
  type: USER_LIST,
  payload: res,
});
export const userListResponse = (res) => ({
  type: USER_LIST_RES,
  payload: res,
});
export const rowUpdate = (res) => ({
  type: ROW_UPDATE,
  payload: res,
});
export const rowUpdateResponse = (res) => ({
  type: ROW_UPDATE_RES,
  payload: res,
});
export const bulkUpdate = (res) => ({
  type: BULK_UPDATE,
  payload: res,
});
export const bulkUpdateResponse = (res) => ({
  type: BULK_UPDATE_RES,
  payload: res,
});
export const searchPatient = (res) => ({
  type: SEARCH_PATIENT,
  payload: res,
});
export const searchPatientResponse = (res) => ({
  type: SEARCH_PATIENT_RES,
  payload: res,
});
export const ticketFilter = (data) => ({
  type: TICKET_FILTER,
  payload: data,
});
export const newTicket = (data) => ({
  type: NEW_TICKET,
  payload: data,
});
export const newTicketResponse = (res) => ({
  type: NEW_TICKET_RES,
  payload: res,
});
export const ticketNotes = (data) => ({
  type: TICKET_NOTES,
  payload: data,
});
export const ticketNotesResponse = (res) => ({
  type: TICKET_NOTES_RES,
  payload: res,
});
export const newTicketNotes = (data) => ({
  type: NEW_TICKET_NOTES,
  payload: data,
});
export const newTicketNotesRes = (res) => ({
  type: NEW_TICKET_NOTES_RES,
  payload: res,
});
export const fileUpload = (data) => ({
  type: FILE_UPLOAD,
  payload: data,
});
export const fileUploadResponse = (res) => ({
  type: FILE_UPLOAD_RES,
  payload: res,
});
export const fileDownload = (data) => ({
  type: FILE_DOWNLOAD,
  payload: data,
});
export const fileDownloadResponse = (res) => ({
  type: FILE_DOWNLOAD_RES,
  payload: res,
});
