import {
    TASK_LIST,
    TASK_LIST_RES,
    NEW_TASK,
    NEW_TASK_RES,
    TASK_FILTER,
    TASK_ROW_UPDATE,
    TASK_ROW_UPDATE_RES,
    TASK_BULK_UPDATE,
    TASK_BULK_UPDATE_RES
} from './Task.types'

export const taskList = (data) => ({
    type: TASK_LIST,
    payload: data,
})
export const taskListResponse = (res) => ({
    type: TASK_LIST_RES,
    payload: res,
})
export const newTask = (data) => ({
    type: NEW_TASK,
    payload: data
})
export const newTaskresponse = (res) => ({
    type: NEW_TASK_RES,
    payload: res
})
export const taskFilter = (data) => ({
    type: TASK_FILTER,
    payload: data,
});
export const taskRowUpdate = (data) => ({
    type: TASK_ROW_UPDATE,
    payload: data,
});
export const taskRowUpdateRes = (data) => ({
    type: TASK_ROW_UPDATE_RES,
    payload: data,
});
export const taskBulkUpdate = (data) => ({
    type: TASK_BULK_UPDATE,
    payload: data,
});
export const taskBulkUpdateRes = (data) => ({
    type: TASK_BULK_UPDATE_RES,
    payload: data,
});