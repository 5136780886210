import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import trilliumLogo from "../../assets/Trillium-health-logo.png";
import Input from "../../Components/Input";
import { login, loginInfo } from "../../Redux/TicketApi/Ticket.action";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import "./style.css";

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    props.login({
      clinicId: 0,
      userName: username,
      password: password,
    });
  };

  useEffect(() => {
    if (props.Tickets.loginResponse) {
      let response = props.Tickets.loginResponse.response;
      if (response.responseCode === 0) {
        let token = response.data.accessToken;
        let user = {
          userId: response.data.userId,
          userName: response.data.fullName,
        };
        localStorage.setItem("accessToken", token);
        localStorage.setItem("owner", JSON.stringify(user));
        localStorage.setItem("currentTab", 'tickets');
        props.loginInfo();
        // window.location.href="/home"
      } else {
        setError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.loginResponse]);

  useEffect(() => {
    if (props.Tickets.cache) {
      let response = props.Tickets.cache.response;
      if (response.responseCode === 0) {
        localStorage.setItem("cache", JSON.stringify(response.data));
        window.location.href = "/tickets";
      }
    }
  }, [props.Tickets.cache]);

  return (
    <div className="login__page scrollbarY--custom">
      <header className="login__header">
        <div className="login__header__bar">
          <a href="#0">
            <img
              src={trilliumLogo}
              alt="trillium-health-logo"
              className="login__header__logo"
            />
          </a>
        </div>
      </header>

      <main className="login__main">
        <section className="login__section">
          <div>
            <h1 className="login__grid__subheading">Ticket Tool</h1>
          </div>

          <div>
            <form onSubmit={handleLogin} className="login__form">
              <div className="login__inputField">
                <Input
                  required
                  type="text"
                  label="Username"
                  variant="outlined"
                  className="login_input"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  error={error}
                />

                <Input
                  required
                  type="password"
                  label="Password"
                  variant="outlined"
                  className="login_input"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  error={error}
                  helperText={error && "Invalid Credentails"}
                />

                <div className="btnWrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="login__submitBtn"
                  >
                    LOGIN
                  </Button>
                </div>

                <div className="helper_text_wrap">
                  <div className="helper_text_large">Forgot your password?</div>
                  <div className="helper_text_small">
                    Contact account manager to reset password
                  </div>
                </div>
              </div>
            </form>
            <div className="footer_container">
              <div className="box_container">
                <div className="box_heading">
                  All rights reserved. Primrose.health 2023
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Tickets: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(login(values)),
  loginInfo: (values) => dispatch(loginInfo(values)),
});
Login.propTypes = {
  login: PropTypes.func,
  loginInfo: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
