import { ofType } from "redux-observable";
// import { Apis } from "../APIs";
import { map, switchMap } from "rxjs/operators";
// import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "../../Utils";
// import { objectToUrl } from "../../Utils/ajax-utils";
import {
    TASK_LIST,
    NEW_TASK,
    TASK_ROW_UPDATE,
    TASK_BULK_UPDATE
} from './Task.types'
import {
    taskListResponse,
    newTaskresponse,
    taskRowUpdateRes,
    taskBulkUpdateRes
} from './Task.action'
import environment from '../../environments/environment'

// const { baseUrl } = environment
const { BaseUrl } = environment
const TaskEpic = {}

TaskEpic.taskListApi = (action$) =>
    action$.pipe(
        ofType(TASK_LIST),
        switchMap((action) =>
            UtilsAjax({
                url: `${BaseUrl}/ticketing-tool/v1/ticket/task/list/all`,
                method: "POST",
                body: JSON.stringify(action.payload),
            }).pipe(
                map(
                    (response) => taskListResponse(response)
                )
            )
        )
    )

TaskEpic.newTaskApi = (action$) =>
    action$.pipe(
        ofType(NEW_TASK),
        switchMap((action) =>
            UtilsAjax({
                url: `${BaseUrl}/ticketing-tool/v1/ticket/task/`,
                method: "POST",
                body: JSON.stringify(action.payload),
            }).pipe(
                map(
                    (response) => newTaskresponse(response)
                )
            )
        )
    )


TaskEpic.taskRowUpdate = (action$) =>
    action$.pipe(
        ofType(TASK_ROW_UPDATE),
        switchMap((action) =>
            UtilsAjax({
                url: `${BaseUrl}/ticketing-tool/v1/ticket/task/${action.payload.id}`,
                method: "PUT",
                body: JSON.stringify(action.payload)
            }).pipe(
                map(
                    (response) => taskRowUpdateRes(response)
                )
            ))
    )

TaskEpic.taskBulkUpdate = (action$) =>
    action$.pipe(
        ofType(TASK_BULK_UPDATE),
        switchMap((action) =>
            UtilsAjax({
                url: `${BaseUrl}/ticketing-tool/v1/ticket/task/`,
                method: "PUT",
                body: JSON.stringify(action.payload)
            }).pipe(
                map(
                    (response) => taskBulkUpdateRes(response)
                )
            ))
    )

export default TaskEpic