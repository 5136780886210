import {
    TASK_LIST_RES,
    NEW_TASK_RES,
    TASK_FILTER,
    TASK_ROW_UPDATE_RES,
    TASK_BULK_UPDATE_RES
} from "./Task.types";

const INITIAL_STATE = {
    taskTable: null,
    newTask: null,
    taskFilter: {
        clinic: null,
        type: null,
        from: null,
        owner: 0,
        dueDate: null,
        status: 0,
        priority: null
    },
    taskRowUpdate: null,
    taskBulkUpdate: null
}

const Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TASK_LIST_RES: {
            return { ...state, ...{ taskTable: action.payload } }
        }
        case NEW_TASK_RES: {
            return { ...state, ...{ newTask: action.payload } }
        }
        case TASK_FILTER: {
            return { ...state, ...{ taskFilter: action.payload } };
        }
        case TASK_ROW_UPDATE_RES: {
            return { ...state, ...{ taskRowUpdate: action.payload } }
        }
        case TASK_BULK_UPDATE_RES: {
            return { ...state, ...{ taskBulkUpdate: action.payload } }
        }
        default:
            return state
    }
}
export default Reducer