export const dateToyyymmdd = (date) => {
  if (date === null) {
    return null
  }
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()
  if (month.length < 2) {
    month = `0${month}`
  }
  if (day.length < 2) {
    day = `0${day}`
  }
  if (Number.isNaN(year)) {
    return null
  }
  return [year, month, day].join('-')
}
export const TimestamptoTime = (timestamp) => {
  if(new Date(timestamp) === "[object Date]"){
    const time = new Date(timestamp).toLocaleTimeString('en-US')
    const split1 = time.split(':')
    const split2 = split1[2].split('')
    const newTime = `${split1[0]}:${split1[1]} ${split2[3]}${split2[4]}`
    return newTime
  }else{
  const time = new Date(timestamp).toLocaleTimeString('en-US')
  const split1 = time.split(':')
  const split2 = split1[2].split('')
  const newTime = `${split1[0]}:${split1[1]} ${split2[3]}${split2[4]}`
  return newTime
  }
}

//MM/DD/YYYY
export const dateTommddyyy = (date) => {
  if (date === null) {
    return null
  }
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear({year: 'numeric'})
  if (month.length < 2) {
    month = `0${month}`
  }
  if (day.length < 2) {
    day = `0${day}`
  }
  if (Number.isNaN(year)) {
    return null
  }
  return [month, day, year].join('/')
}

export const calculateAge = (birthday) => {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}
