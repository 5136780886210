import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Chip, Dialog, IconButton, Slide, makeStyles } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  TimestamptoTime,
  dateTommddyyy,
} from "../../HelperFunctions/TimeDateTimestamp";
import SendIcon from "@material-ui/icons/Send";
import {
  fileDownload,
  fileDownloadResponse,
  fileUpload,
  newTicketNotes,
  ticketNotes,
} from "../../Redux/TicketApi/Ticket.action";
import noDataFound from "../../assets/nodata-found.png";
import InfiniteScroll from "react-infinite-scroll-component";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import FileIcon from "../../assets/icons/file-icon.svg";
import Input from "../Input";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  search: {
    border: "1px solid grey",
    position: "relative",
    borderRadius: "15px",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    marginLeft: 0,
    marginTop: "0.5rem",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    color: "black",
    padding: "6px 0px 4px 0px",
    paddingLeft: "1rem",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
  chip: {
    height: "40px",
  },
}));

function NotesDialog(props) {
  const classes = useStyles();
  const [notes, setNotes] = useState([]);
  const [totalRecords, setTotalRecords] = useState("");
  const [typeValue, setTypeValue] = useState("");
  // const [commentsList, setCommentsList] = useState([]);
  const [page, setPage] = useState(0);
  // const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [currentFile, setCurrentFile] = useState("");

  useEffect(() => {
    if (props.Tickets.ticketNotes) {
      let data = props.Tickets.ticketNotes.response.data;
      // setNotes(data.results);
      if (page > 0) {
        const newData = data.results;
        setNotes((prevNotes) => [...prevNotes, ...newData]);
      } else {
        setNotes(data.results);
      }
      setTotalRecords(data.totalRecords);
      // let comments = data.results.map((x) => {
      //   return {
      //     title: x.comment,
      //     id: x.ticketCommentId,
      //   };
      // });
      // setCommentsList(autocompleteOptions.length > 0 ? comments : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.ticketNotes]);

  const createComment = () => {
    const user = JSON.parse(localStorage.getItem("owner"));
    const commonNoteData = {
      ticketCommentId: null,
      comment: typeValue ? typeValue : "",
      ticketSubject: null,
      ticketId: props.ticketInfo.ticketId ? props.ticketInfo.ticketId : null,
      taskId: props.ticketInfo.taskId ? props.ticketInfo.taskId : null,
      createdUserId: user.userId,
      createdUserName: user.userName,
      createdDate: null,
    };

    // props.createNewNotes(commonNoteData);
    setTypeValue("");

    // Check if both comment and files are present
    if (typeValue.length > 3 && Object.keys(selectedFiles).length > 0) {
      props.createNewNotes(commonNoteData);

      const formData = new FormData();
      formData.append("file", selectedFiles[0]);
      props.fileUpload({
        data: formData,
        ticketId: props.ticketInfo.ticketId ? props.ticketInfo.ticketId : "",
        taskId: props.ticketInfo.taskId ? props.ticketInfo.taskId : "",
        createdUserId: user.userId,
      });
      setSelectedFiles({});
    } else {
      // Check if only comment is present
      if (typeValue.length > 3) {
        props.createNewNotes(commonNoteData);
      }

      // Check if only files are present
      if (Object.keys(selectedFiles).length > 0) {
        const formData = new FormData();
        formData.append("file", selectedFiles[0]);
        props.fileUpload({
          data: formData,
          ticketId: props.ticketInfo.ticketId ? props.ticketInfo.ticketId : "",
          taskId: props.ticketInfo.taskId ? props.ticketInfo.taskId : "",
          createdUserId: user.userId,
        });
        setSelectedFiles({});
      }
    }
  };

  useEffect(() => {
    if (props.Tickets.newTicketNotes) {
      let data = props.Tickets.newTicketNotes.response.data;
      if (data.ticketCommentId) {
        const ticketNotesData = {
          start: page,
          limit: 10,
          sortBy: null,
          sortDirection: null,
          ticketCommentDto: {
            ticketCommentId: null,
            comment: null,
            ticketId: props.ticketInfo.ticketId
              ? props.ticketInfo.ticketId
              : null,
            taskId: props.ticketInfo.taskId ? props.ticketInfo.taskId : null,
          },
        };
        props.ticketNotes(ticketNotesData);
        console.log("call 1");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.newTicketNotes]);

  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  const searchComments = (e) => {
    // let inputValues = e.target.value;
    // setAutocompleteOptions(inputValues);
    if (e.target.value.length > 1) {
      console.log("call 2");
      props.ticketNotes({
        start: 0,
        limit: 10,
        sortBy: null,
        sortDirection: null,
        ticketCommentDto: {
          ticketCommentId: null,
          comment: e.target.value,
          // ticketId: localStorage.getItem("ticketId"),
          ticketId: props.ticketInfo.ticketId
            ? props.ticketInfo.ticketId
            : null,
          taskId: props.ticketInfo.taskId ? props.ticketInfo.taskId : null,
        },
      });
      setPage(0);
    } else if (e.target.value.length === 0) {
      console.log("call 3");
      props.ticketNotes({
        start: 0,
        limit: 10,
        sortBy: null,
        sortDirection: null,
        ticketCommentDto: {
          ticketCommentId: null,
          comment: null,
          ticketId: props.ticketInfo.ticketId
            ? props.ticketInfo.ticketId
            : null,
          taskId: props.ticketInfo.taskId ? props.ticketInfo.taskId : null,
        },
      });
      setPage(0);
    }
  };

  const fetchData = () => {
    console.log("call 6");
    props.ticketNotes({
      start: page + 1,
      limit: 10,
      sortBy: null,
      sortDirection: null,
      ticketCommentDto: {
        ticketCommentId: null,
        comment: null,
        ticketId: props.ticketInfo.ticketId ? props.ticketInfo.ticketId : null,
        taskId: props.ticketInfo.taskId ? props.ticketInfo.taskId : null,
      },
    });
    setPage((prevPage) => prevPage + 1);
  };

  const hasMore = notes.length < totalRecords;

  const handleClose = () => {
    props.handleClose();
    setPage(0);
    setNotes([]);
  };

  const handleUploadFiles = (event) => {
    let e = event.target.files;
    let files = selectedFiles;
    let length = Object.keys(files).length;
    Object.keys(e).forEach((x, i) => {
      files[length + i] = e[x];
    });
    setSelectedFiles({ ...files });
  };

  const handleAttachmentClick = () => {
    document.getElementById("upload").click();
  };

  const handleRemoveFile = (fileKey) => {
    // Create a copy of the selectedFiles object
    const updatedFiles = { ...selectedFiles };

    // Remove the specific file using the fileKey
    delete updatedFiles[fileKey];

    // Update the state with the modified object
    setSelectedFiles(updatedFiles);
  };

  const handleDownload = (x) => {
    // console.log(x);
    props.fileDownload(x.fileName);
    setCurrentFile(x.fileName);
  };

  useEffect(() => {
    if (props.Tickets.fileUpload) {
      let response = props.Tickets.fileUpload.response;
      if (response.data) {
        const ticketNotesData = {
          start: page,
          limit: 10,
          sortBy: null,
          sortDirection: null,
          ticketCommentDto: {
            ticketCommentId: null,
            comment: null,
            ticketId: props.ticketInfo.ticketId
              ? props.ticketInfo.ticketId
              : null,
            taskId: props.ticketInfo.taskId ? props.ticketInfo.taskId : null,
          },
        };
        props.ticketNotes(ticketNotesData);
        console.log("call 7");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.fileUpload]);

  const downloadFiles = (content, fileName, contentType) => {
    if (!contentType) contentType = "application/octet-stream";
    var a = document.createElement("a");
    var blob = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    if (props.Tickets.fileDownload) {
      let blobData = props.Tickets.fileDownload.response;
      let fileName = currentFile;
      let fileType = currentFile.split(".")[1];
      downloadFiles(blobData, fileName, fileType);
      props.fileDownloadResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.fileDownload]);

  return (
    <Dialog
      onClose={handleClose} 
      aria-labelledby="simple-dialog-title"
      open={props.open}
      className="notes_dialog"
      TransitionComponent={Slide}
      TransitionProps={{
        direction: "left",
      }}
      maxWidth="sm"
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <div className="notes_container" id="scrollableDivChat">
        <div className="header_section">
          <div className="header_section_one">
            <div className="header_section_one_text">
              {totalRecords} Comments
            </div>
            <CloseIcon className="close_btn" onClick={handleClose} />{" "}
          </div>
          <div className="header_section_two">
            <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              {props.ticketInfo.number
                ? props.ticketInfo.number + " - " + props.ticketInfo.subject
                : props.ticketInfo.subject}
            </div>
            <div className="detailed_section_dec">
              <div>
                {props.ticketInfo.date ? (
                  <AccessTimeIcon style={{ fontSize: "1rem" }} />
                ) : (
                  ""
                )}
              </div>
              <span style={{ fontSize: "0.9rem" }}>
                {" "}
                {props.ticketInfo.date}
              </span>
            </div>
            <div className="description">{props.ticketInfo.description}</div>
            <div
              className="search_comments"
              style={{
                border: "1px solid grey",
                borderRadius: "10px",
                margin: "10px 0 0",
                padding: "0 5px",
              }}
            >
              <Input
                placeholder="Search comments..."
                label=""
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={searchComments}
              ></Input>
            </div>
          </div>
        </div>
        <div className="body_container">
          <InfiniteScroll
            dataLength={notes.length}
            next={fetchData}
            hasMore={hasMore}
            loader={""}
            scrollableTarget="scrollableDivChat"
          >
            {notes.map((x) => (
              <div className="notes_section">
                <div className="notes_section_div">
                  <div style={{ fontWeight: "600" }}>
                    {" "}
                    {x.createdUserName ? sentanceCase(x.createdUserName) : ""}
                  </div>
                  <div style={{ marginLeft: "15px" }}>
                    {x.createdDate
                      ? dateTommddyyy(x.createdDate) +
                        " " +
                        TimestamptoTime(x.createdDate)
                      : ""}
                  </div>
                </div>
                <div className="comment">
                  {x.type === 1 || x.type === null ? (
                    // Render normal content
                    sentanceCase(x.comment)
                  ) : (
                    // Render Link
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        handleDownload(x);
                      }}
                    >
                      {
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "10px",
                            padding: "0.2rem",
                          }}
                        >
                          <img
                            style={{ width: "30px", height: "30px" }}
                            src={FileIcon}
                            alt="file"
                          />{" "}
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {sentanceCase(x.comment)}
                          </span>
                        </div>
                      }
                    </span>
                  )}
                </div>
              </div>
            ))}
          </InfiniteScroll>

          {notes.length === 0 && (
            <div>
              <img
                style={{ width: "100%", margin: "50px 0" }}
                src={noDataFound}
                alt={"NoDataFound"}
              />
            </div>
          )}
        </div>
        <div className="comment_replay_section">
          <div className="file_uploader">
            {selectedFiles &&
              Object.keys(selectedFiles).map((x, i) => (
                <Chip
                  key={x}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={FileIcon} alt="File" />
                      <span>{selectedFiles[x].name}</span>
                    </div>
                  }
                  onDelete={() => handleRemoveFile(x)}
                  className={classes.chip}
                />
              ))}
          </div>
          <div className="comment_fields">
            <input
              type="text"
              placeholder="Send a comment"
              required
              value={typeValue}
              onChange={(e) => setTypeValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && typeValue.length > 3) {
                  e.preventDefault();
                  createComment();
                }
              }}
            />
            <div>
              <IconButton
                style={{
                  backgroundColor: "#e7f7f4",
                  borderRadius: 0,
                }}
                onClick={handleAttachmentClick}
              >
                <input
                  value={""}
                  type="file"
                  id="upload"
                  hidden
                  onChange={handleUploadFiles}
                />
                <AttachFileIcon />
              </IconButton>
            </div>
            <div style={{ cursor: "pointer" }}>
              <IconButton
                style={{
                  backgroundColor: "#e7f7f4",
                  borderRadius: "0 12px 12px 0",
                  cursor: "pointer",
                }}
                aria-label="send comment"
                onClick={createComment}
                disabled={
                  typeValue.length < 3 &&
                  Object.keys(selectedFiles).length === 0
                }
              >
                <SendIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  Tickets: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  createNewNotes: (values) => dispatch(newTicketNotes(values)),
  ticketNotes: (values) => dispatch(ticketNotes(values)),
  fileUpload: (values) => dispatch(fileUpload(values)),
  fileDownload: (values) => dispatch(fileDownload(values)),
  fileDownloadResponse: (values) => dispatch(fileDownloadResponse(values)),
});
NotesDialog.propTypes = {
  createNewNotes: PropTypes.func,
  ticketNotes: PropTypes.func,
  fileUpload: PropTypes.func,
  fileDownload: PropTypes.func,
  fileDownloadResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesDialog);
