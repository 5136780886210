/**
 * @fileOverview Manages types of the particular action in the redux
 * @author
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */

export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const LOGIN = "LOGIN";
export const LOGIN_RES = "LOGIN_RES";
export const TICKET_LIST = "TICKET_LIST";
export const TICKET_LIST_RES = "TICKET_LIST_RES";
export const LOGIN_INFO = "LOGIN_INFO";
export const LOGIN_INFO_RES = "LOGIN_INFO_RES";
export const CACHE_LIST = "CACHE_LIST";
export const CACHE_LIST_RES = "CACHE_LIST_RES";
export const TYPE_LIST = "TYPE_LIST";
export const TYPE_LIST_RES = "TYPE_LIST_RES";
export const USER_LIST = "USER_LIST";
export const USER_LIST_RES = "USER_LIST_RES";
export const ROW_UPDATE = "ROW_UPDATE";
export const ROW_UPDATE_RES = "ROW_UPDATE_RES";
export const BULK_UPDATE = "BULK_UPDATE";
export const BULK_UPDATE_RES = "BULK_UPDATE_RES";
export const SEARCH_PATIENT = "SEARCH_PATIENT";
export const SEARCH_PATIENT_RES = "SEARCH_PATIENT_RES";
export const TICKET_FILTER = "TICKET_FILTER ";
export const NEW_TICKET = "NEW_TICKET";
export const NEW_TICKET_RES = "NEW_TICKET_RES";
export const TICKET_NOTES = "TICKET_NOTES";
export const TICKET_NOTES_RES = "TICKET_NOTES_RES";
export const NEW_TICKET_NOTES = "NEW_TICKET_NOTES";
export const NEW_TICKET_NOTES_RES = "NEW_TICKET_NOTES_RES";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_RES = "FILE_UPLOAD_RES";
export const FILE_DOWNLOAD = "FILE_DOWNLOAD";
export const FILE_DOWNLOAD_RES = "FILE_DOWNLOAD_RES";
